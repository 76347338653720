import { Box } from '@mui/material'
import React from 'react'
import Text from '../../../../components/customText/Text'

function PayrollDashboard() {
  return (
    <Box sx={{ textAlign: 'center', alignItems: 'center', width: "100%" }}>
      <Text>Payroll Dashboard</Text>
    </Box>
  )
}

export default PayrollDashboard