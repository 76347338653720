import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, Skeleton, Slide } from '@mui/material';
import MainStyles from '../../MainStyles';
import ToggleSwitch from '../../../../../components/toggle/CustomToggle';
import CustomMenu from '../../configComponents/customMenu/Menu';
import Text from '../../../../../components/customText/Text';
import Button from '../../../../../components/customButton/Button';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import Input from '../../../../../components/input/Input';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import JobTitleApi from '../../../../../apis/configurations/placement/JobTitleApi';
import { isValid, validate_charWithSpace, onCharactersAndNumbersWithSpaceChange } from "../../../../../components/Validation";
import LocalStorage from "../../../../../utils/LocalStorage";
import { addSuccessMsg, addErrorMsg, addWarningMsg, addLoader, removeLoader, NoDataFound, NoPermission, BlackToolTip } from '../../../../../utils/utils';
import ConfigApi from '../../../../../apis/configurations/ConfigApi';
import LoadingButton from '../../../../../components/customButton/LoadingButton';
import ReusableDelete from '../../../../../components/reuablePopup/ReusableDelete';
import ReusableAlert from '../../../../../components/reuablePopup/ReusableAlert';
import { ReactComponent as MenuIcon } from '../../../../../assets/svg/MenuIcon.svg'
import EditIcon from '../../../../../assets/svg/newEdit.svg';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
        // height: '420px',
        width: '692px',
        padding: '0px !important',
        borderRadius: "12px",
    },
    "& .MuiDialogContent-root": {
        padding: '0px !important',
    },
    "& .MuiDialogActions-root": {
        padding: '0px !important'
    },
    "& .MuiDialog-container": {
        background: 'rgba(0, 0, 0, 0.55) !important'
    }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} timeout={500} />;
});

function JobTitle({ current }) {
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = MainStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [getData, setGetData] = useState([]);
    const [alert, setAlert] = useState(false);
    const [error, setError] = useState({});
    const recentSection = useRef(null);
    const [loading, setLoading] = useState(true);
    const innerBoxRef = useRef(null);
    const [total, setTotal] = useState()
    const [inactiveOpen, setInactiveOpen] = useState(false);
    const [inActiveData, setInactiveData] = useState(null);
    const [recentActivityShow, setrecentActivityShow] = useState(false)
    const [state, setState] = useState({
        serial_no: '',
        id: '',
        name: '',
        is_active: true,
        description: '',
    });
    const [filter, setFilter] = useState({
        limit: 5,
        page: 1,
        search: "",
    });
    const [activityData, setActivityData] = useState([])
    const [activityFilter, setActivityFilter] = useState({
        limit: 10,
        page: 1,
    })

    useEffect(() => {
        if( !LocalStorage.getStartTour()?.orgconfig){
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "organization_placement_view" && item.is_allowed == true))) {
            getListing(filter);
            getActivity(activityFilter);
        }}
        // eslint-disable-next-line
    }, [])


    const [activityTotal, setActivityTotal] = useState();
    const activityRef = useRef(null);

    const getActivity = (args, popup) => {
        if (activityFilter.limit <= 10) {
            setLoading(true)
        } else {
            setLoading(false)
        }
        ConfigApi.getActivity(15, "", args).then((response) => {
            setTimeout(() => {
                if (response.data.statusCode == 1003) {
                    setLoading(false)
                    setActivityData(response.data.data);
                    setActivityTotal(response.data.pagination.total);
                    if (popup) { }
                    else {
                        setrecentActivityShow(true)
                    }
                }
            }, 300)
            removeLoader();
        });
    };

    const activityHandleScroll = () => {
        const { current } = activityRef;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                // getCategory({...filter})
                if (activityTotal >= activityFilter.limit) {
                    addLoader("loader");
                    activityFilter.limit = activityFilter.limit + 10;
                    setActivityFilter({ ...activityFilter })
                    getActivity(activityFilter, true);
                }
            }
        }
    };

    const scrollDown = () => {
        window.scroll({
            top: recentSection.current.scrollIntoView(),
            behavior: 'smooth'
        })
        setrecentActivityShow(false)
    }
    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
        handleValidations(e);
    };
    const handleValidations = (e) => {
        let input = e.target;
        let err = error;
        switch (input.name || input.tagName) {
            case "name":
                err.name = validate_charWithSpace(input.value);
                break;
            default:
                break;
        }
        setError(err);
    }

    const handleClickOpen = () => {
        setOpen(true);
        setAlert(false);
        setState({
            serial_no: '',
            id: '',
            name: '',
            is_active: true,
            description: '',
        })
        setError({});
    };

    const handleDialogClose = () => {
        setOpen(false);
        if (isEditable) {
            setIsEditable(isEditable)
        }
        setIsEditable(false);
    };

    const handleEditClick = (data) => {
        // getIndex(data.id)
        setError({})
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "organization_placement_edit" && item.is_allowed == true)))) {
            setOpen(true);
            setAlert(false)
            setAnchorEl(null);
            setIsEditable(false);
            setState({ ...data });
        } else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }
    };

    const handleViewClick = (data) => {
        // getIndex(data.id)
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "organization_placement_view" && item.is_allowed == true)))) {
            setOpen(true);
            setAlert(false)
            setAnchorEl(null);
            setIsEditable(true);
            setState({ ...data });
        } else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }
    }
    const [val, setVal] = useState()
    const handleDeleteClick = (args) => {
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "organization_placement_delete" && item.is_allowed == true)))) {
            setVal(args)
            setOpen(true);
            setAlert(true)
        } else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }
    }
    const validateAll = () => {
        let { name } = state;
        let errors = {};
        errors.name = validate_charWithSpace(name);
        return errors;

    };
    const handleDelete = () => {
        deleteTeam(val.id)
        setOpen(false);
    }

    // const handleSubmit = () => {
    //     let errors = validateAll();
    //     if (isValid(errors)) {

    //         handleDialogClose();
    //     } else {
    //         setError(errors);
    //     }
    // }
    const handleSaveAndEdit = () => {
        let errors = validateAll();
        if (isValid(errors)) {
            if (state.id != "") {
                updateApi(state.id)
            } else {
                storeApi(state)
            }

        } else {
            setError(errors);
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleStatus = (event, items) => {
        if ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "organization_placement_edit" && item.is_allowed == true)))) {
            if (event.target.checked) {
                items['is_active'] = event.target.checked
                updateStatus(items)
            } else {
                items['is_active'] = event.target.checked;
                setInactiveData(items);
                setInactiveOpen(true);
            }
        } else {
            addWarningMsg("You don't have permission to perform this action. Please contact the admin")
        }
    }
    const getListing = (args) => {
        if (filter.limit < 10) {
            setLoading(true)
        } else {
            setLoading(false)
        }
        // setLoading(true)
        JobTitleApi.getListing(args).then((response) => {
            setLoading(false);
            removeLoader();
            if (response.data.statusCode == 1003) {
                setGetData(response.data.data);
                setTotal(response.data.pagination.total);
            }
        });
    };

    const storeApi = (data) => {
        data.request_id = LocalStorage.uid()
        setLoading(true)
        JobTitleApi.storeApi(data).then((response) => {
            if (response.data.statusCode == 1003) {
                setLoading(false)
                handleDialogClose();
                getListing(filter);
                getActivity(activityFilter);
                addSuccessMsg(response.data.message);
            } else {
                setLoading(false)
                addErrorMsg(response.data.message);
            }
        });
    };

    const updateStatus = (args) => {
        let data = {
            request_id: LocalStorage.uid(),
            is_active: args.is_active
        }
        setLoading(true)
        JobTitleApi.updateStatus(data, args.id).then((response) => {
            if (response.data.statusCode == 1003) {
                setLoading(false)
                setInactiveData(null);
                setInactiveOpen(false)
                addSuccessMsg(response.data.message);
                getListing(filter);
                getActivity(activityFilter);
            } else {
                setInactiveOpen(false)
                getListing(filter)
                getActivity(activityFilter);
                setLoading(false)
                addErrorMsg(response.data.message);
            }
        });
    };
    const updateApi = (dataId) => {
        let data = { ...state, request_id: LocalStorage.uid() };
        setLoading(true)
        JobTitleApi.updateApi(data, dataId).then((response) => {
            if (response.data.statusCode == 1003) {
                setLoading(false)

                handleDialogClose();
                getListing(filter);
                getActivity(activityFilter);
                addSuccessMsg(response.data.message);
            } else {
                setLoading(false)

                addErrorMsg(response.data.message);
            }
        });
    };

    const deleteTeam = (dataId) => {
        let data = { request_id: LocalStorage.uid() };
        JobTitleApi.deleteApi(data, dataId).then((response) => {
            if (response.data.statusCode == 1003) {
                getListing(filter);
                getActivity(activityFilter);
                addSuccessMsg(response.data.message);
            } else {
                addErrorMsg(response.data.message);
            }
        });
    };
    const handleScroll = () => {
        const { current } = innerBoxRef;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;

            if (scrollTop + clientHeight >= scrollHeight - 5) {
                // getCategory({...filter})
                // if (total >= filter.limit) {
                //     setFilter({ ...filter, limit: filter.limit + 5, page: 1 })
                //     getListing({ ...filter, limit: filter.limit + 5, page: 1, });
                // }
                if (Number(total) >= filter.limit) {
                    addLoader(true);
                    loadMoreData();
                }
            } else {
                // console.log("else inside");
            }
        }
    };
    const loadMoreData = () => {
        filter.limit = Number(filter.limit) + 5;
        setFilter({ ...filter });
        getListing(filter);
    }

    const handleCloseInActive = () => {
        setInactiveData(null);
        setInactiveOpen(false);
        getListing(filter);
    }
    // const closeBtn = () => {
    //     setLoading(true);
    //     setFilter({ ...filter, search: "" });
    //     setTimeout(() => {
    //         JobTitleApi.getListing({ ...filter, search: "" }).then((res) => {
    //             setLoading(false);
    //             setGetData(res.data.data);
    //             // setPagination(res.data.pagination);
    //         })
    //     }, 2000);
    // }
    // const handleSearch = (e) => {
    //     const text = e.target.value;
    //     setFilter({ ...filter, search: text })
    //     if (text.length > 1 || text.length == 0) {
    //         if (!loading) {
    //             setLoading(true);
    //             setTimeout(() => {
    //                 searchAPICall(e.target.value);
    //             }, 2000);
    //         }
    //     }
    // }
    // const searchAPICall = (text) => {
    //     // JobTitleApi.getListing({ ...filter, search: text }, { ...pagination, currentPage: 1, limit: 5 }).then((res) => {
    //     JobTitleApi.getListing({ ...filter, search: text }).then((res) => {

    //         setLoading(false);
    //         setGetData(res.data.data);
    //         // setPagination(res.data.pagination);
    //     })
    // }
    return (
        <div>
            {LocalStorage.getStartTour()?.orgconfig ? NoDataFound():
                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "organization_placement_view" && item.is_allowed == true))) ?
                    <Box sx={{ height: '70vh', overflow: 'auto', padding: '16px !important' }}>
                        <Box className={classes.activeItemBox}>
                            <Box className={classes.activeBoxHeading}>
                                <Grid container>
                                    <Grid item lg={7} display={"flex"} justifyContent={'center'} flexDirection={"column"}>
                                        <Text profileTitle>{current}</Text>
                                    </Grid>

                                    {/* <Grid item lg={5}>
                            <div className={classes.searchField}>
                                <input
                                    type="text"
                                    value={filter.search}
                                    onChange={handleSearch}
                                    className={classes.globalSearchInput}
                                    placeholder="Search"
                                // onKeyUp={(e) => { e.key == 'Enter' && e.preventDefault(); }}
                                // onKeyDown={(e) => { e.key == 'Enter' && e.preventDefault(); }}
                                />
                                <button
                                    type="button"
                                    className={classes.searchIcon}
                                >
                                    {filter.search.length == 0 ? <img src={Search} alt="Search" /> : <CloseRoundedIcon sx={{ cursor: "pointer" }} onClick={closeBtn} />}
                                </button>
                            </div>
                        </Grid> */}
                                </Grid>

                            </Box>
                            <Box sx={{ height: '35vh', overflow: 'auto' }} ref={innerBoxRef} onScroll={handleScroll}>
                                {
                                    loading ?
                                        <>
                                            {[1, 2, 3].map((item, index) => (
                                                <Grid spacing={2} container key={index} mt={3}>
                                                    <Grid item lg={4} md={4}>
                                                        <Skeleton animation="wave" />
                                                        <Skeleton animation="wave" />
                                                    </Grid>
                                                    <Grid item lg={4} md={4}>
                                                        <Skeleton animation="wave" />
                                                        <Skeleton animation="wave" />
                                                    </Grid>
                                                    <Grid item lg={2} md={2}>
                                                        <Skeleton animation="wave" height={40} />
                                                    </Grid>
                                                    <Grid item lg={1} md={1}>
                                                        <Skeleton animation="wave" />
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </>
                                        :
                                        <>
                                            {getData.length > 0 ?
                                                <>
                                                    {getData.map((item, index) => (
                                                        <Box className={classes.descriptionBoxStyle} key={index}>
                                                            <Grid container alignItems="center">
                                                                <Grid item lg={7} md={6} sm={6} xs={12} container direction={'column'} gap={1}>
                                                                    <Text mediumBlackColor >{item.name}</Text>
                                                                    <Text greyLabel>
                                                                        <BlackToolTip arrow title={item.description}>{item.description ? item.description.length > 30 ? item.description.slice(0, 30) + '...' : item.description : "--"}
                                                                        </BlackToolTip>
                                                                    </Text>
                                                                </Grid>
                                                                <Grid item lg={3} md={3} sm={3} xs={12} container direction={'column'} gap={1}>
                                                                    <Text mediumBlackColor>Created By</Text>
                                                                    <Text greyLabel>
                                                                        {
                                                                            item.created_by && item.created_by.length > 16 ?
                                                                                <BlackToolTip title={item.created_by} placement="top" arrow>
                                                                                    {item.created_by.slice(0, 16) + (item.created_by.length > 16 ? "..." : "")}
                                                                                </BlackToolTip>
                                                                                : item.created_by ? item.created_by : '--'
                                                                        }

                                                                    </Text>
                                                                </Grid>
                                                                <Grid item lg={1} md={1} sm={1} xs={12} container alignItems={'center'}>
                                                                    <ToggleSwitch name='is_active' isActive={item.is_active} switchChange={(e) => handleStatus(e, item)} sx={{ height: '24px !important' }} />
                                                                </Grid>
                                                                <Grid item lg={1} md={1} sm={1} xs={12} container alignItems={'center'} justifyContent={'center'}>
                                                                    <CustomMenu
                                                                        Icon={<MenuIcon />}
                                                                        handleMenuClick={handleMenuClick}
                                                                        anchorEl={anchorEl}
                                                                        isOpen={Boolean(anchorEl)}
                                                                        onClose={handleClose}
                                                                        children={item.is_editable ?
                                                                            [{ color: '#171717', label: "View", Click: () => handleViewClick(item) },
                                                                            { color: '#171717', label: "Edit", Click: () => handleEditClick(item) },
                                                                            { color: '#171717', label: "Delete", Click: () => handleDeleteClick(item) }
                                                                            ]
                                                                            :
                                                                            [{ color: '#171717', label: "View", Click: () => handleViewClick(item) }]}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Box>))}
                                                </>
                                                :
                                                <>
                                                    {NoDataFound("", "No Data Found", "50%", "35")}
                                                </>
                                            }
                                        </>
                                }
                            </Box>
                            <>
                                {
                                    (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "organization_placement_create" && item.is_allowed == true))) ?
                                        <Button addTitleBtn onClick={() => handleClickOpen()}>Add Job Title</Button> :
                                        <Button addTitleBtnDisable>Add Job Title</Button>
                                }
                            </>
                            {alert ? <ReusableDelete newDelete={true} content={"Do you want to delete this job title?"} open={open} setOpen={setOpen} onClick={() => { handleDelete() }} /> :
                                <BootstrapDialog
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleDialogClose}
                                    aria-labelledby="customized-dialog-title"
                                    open={open}
                                    fullWidth={true}
                                    maxWidth={"md"}
                                >
                                    <DialogContent>
                                        {
                                            isEditable ?
                                                <Box padding={'30px'}>
                                                    <Grid container spacing={2} columnSpacing={'32px'} alignItems={"center"}>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Text mediumViewAmt>Job Title</Text>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={12} textAlign={"end"}>
                                                            {state.is_editable ? <img onClick={() => { setIsEditable(false) }} src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} /> : ""}
                                                        </Grid>
                                                        <Grid item lg={4} md={4} sm={6} xs={12} mt={2}>
                                                            <Text mediumGreyHeader1>Job Title Name</Text>
                                                            <Text mediumBlack14 pt={0.8} noWrap>{state.name ? state.name : "--"}</Text>
                                                        </Grid>
                                                        <Grid item lg={4} md={4} sm={6} xs={12} mt={2}>
                                                            <Text mediumGreyHeader1>Status</Text>
                                                            <Text mediumBlack14 pt={0.8} noWrap>{state.is_active ? "Active" : "In Active"}</Text>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                                            <Text mediumGreyHeader1>Description</Text>
                                                            <Text mediumBlack14 pt={0.8} noWrap>{state.description ? state.description : "--"}</Text>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                :
                                                <Box padding={'38px 30px 32px 30px '}>
                                                    <Box mb={4}>
                                                        <Text mediumViewAmt>{state.id === "" ? "Add Job Title" : "Job Title"}</Text>
                                                    </Box>
                                                    <Grid container spacing={2} columnSpacing={'32px'}>
                                                        <Grid item lg={6} md={6} sm={6} xs={12} >
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'name',
                                                                    value: state.name,
                                                                    type: 'text',
                                                                    inputProps: { maxLength: 33 },
                                                                }}
                                                                handleChange={handleChange}
                                                                clientInput
                                                                onKeyPress={onCharactersAndNumbersWithSpaceChange}
                                                                labelText={'Job Title'}
                                                                error={error.name}
                                                            />
                                                            <Text errorText> {error.name ? error.name : ""}</Text>
                                                        </Grid>
                                                        {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Select
                                            name='is_active'
                                            value={state.is_active}
                                            onChange={handleChange}
                                            label={'Status'}
                                            options={statusList}
                                            disabled={isEditable}
                                            helperText={error.is_active ? <Text errorText> {error.is_active ? error.is_active : ""}</Text> : ""}
                                        />
                                    </Grid> */}
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                multiline={true}
                                                                rows={3}
                                                                inputProps={{
                                                                    name: 'description',
                                                                    value: state.description,
                                                                    type: 'text',
                                                                    inputProps: { maxLength: 100 },
                                                                }}
                                                                handleChange={handleChange}
                                                                descriptionFormControl1
                                                                descriptionInput
                                                                labelText={'Description'}
                                                                placeholder={'Type Something'}
                                                            />
                                                        </Grid>

                                                    </Grid>
                                                    <Box display={'flex'} justifyContent={'end'} gap={2} mt={5}>
                                                        <Button sx={{ width: "108px !important" }} cancelOutlineBlue onClick={handleDialogClose} >Cancel</Button>
                                                        <LoadingButton saveBtn loading={loading} onClick={() => handleSaveAndEdit()}>Save</LoadingButton>
                                                        {/* <Button popupSaveBlue onClick={handleSaveAndEdit} >{isEditable ? 'Edit' : 'Save'}</Button> */}
                                                    </Box>
                                                </Box>
                                        }
                                    </DialogContent>
                                </BootstrapDialog>}
                        </Box>
                        {
                            inactiveOpen && <ReusableAlert message={true} open={inactiveOpen} handleClose={handleCloseInActive} onClick={() => { updateStatus(inActiveData) }} />
                        }
                        <Box className={classes.activeItemBox} mt={4} mb={6} ref={recentSection}>
                            <Box mb={3}>
                                <Text RegularBlack1>Recent Activities</Text>
                            </Box>
                            <Box sx={{ height: '40vh', overflowY: 'auto' }}
                                ref={activityRef}
                                onScroll={activityHandleScroll}>
                                {loading ?
                                    <>
                                        {[1, 2, 3].map((item, index) => (
                                            <Grid container key={index} mt={3}>
                                                <Grid item lg={8}>
                                                    <Skeleton animation="wave" width="200px" />
                                                    <Skeleton animation="wave" width="200px" />

                                                </Grid>
                                                <Grid item lg={3}>
                                                    <Skeleton animation="wave" width="200px" />
                                                    <Skeleton animation="wave" width="200px" />

                                                </Grid>
                                            </Grid>
                                        ))}
                                    </> :
                                    <>
                                        {activityData.length > 0 ?
                                            <>
                                                {activityData.map((value) => (
                                                    <Box className={classes.descriptionBoxStyle} mb={2}>
                                                        <Grid container spacing={6}>
                                                            <Grid container item lg={4} md={4} sm={6} xs={12} alignItems={"center"}>
                                                                <Text mediumBlackColor>{value.user_name} {value.action}<br /><Text greyLabel mt={"5px"}>{value.created_at}</Text></Text>
                                                            </Grid>
                                                            <Grid item lg={8} md={8} sm={6} xs={12} display={"flex"} alignItems={"center"}>
                                                                {
                                                                    value.change_log.length > 0 && value.change_log.map((item) => (
                                                                        <Text mediumGrey2>{item}.</Text>
                                                                    ))
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                ))}
                                            </> :
                                            <>
                                                {NoDataFound("", "No Data Found", "50%", "35")}
                                            </>
                                        }
                                    </>
                                }
                            </Box>
                        </Box>
                        {recentActivityShow ?
                            <Grid container xs={6} justifyContent={'center'}>
                                <Button addButton
                                    sx={{
                                        position: 'fixed', bottom: 20, borderRadius: "30px !important", paddingLeft: "20px", paddingRight: "20px", font: "14px Quicksand !important", fontWeight: `${700} !important`,
                                        "@media (min-width: 300px) and (max-width: 1420px)": {
                                            font: "12px Quicksand !important",
                                            fontWeight: `${700} !important`
                                        }
                                    }}
                                    onClick={scrollDown}
                                    endIcon={<ArrowDownwardRoundedIcon sx={{ width: "24px" }} />}>New Activity</Button>
                            </Grid>
                            : ''}
                    </Box>
                    :
                    <>
                        {NoPermission()}
                    </>
            }
        </div>
    )
}

export default JobTitle