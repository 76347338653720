import { Box, Grid, Stack } from "@mui/material";
import Text from "../../../../components/customText/Text";
import Select from "../../../../components/select/Select";
import Input from "../../../../components/input/Input";
import Date from "../../../../components/datePicker/Date";
import FileInput from "../../../../components/muiFileInput/FileInput";
import Button from "../../../../components/customButton/Button";
import { useEffect, useState } from "react";
import CommonApi from "../../../../apis/CommonApi";
import PayrollApi from "../../../../apis/admin/payroll/ExpenseManagement";
import moment from "moment";
import { addErrorMsg, addLoader, addSuccessMsg, addWarningMsg, dateFormat, removeLoader } from "../../../../utils/utils";
import ReusablePopup from "../../../../components/reuablePopup/ReusablePopup";
import AddReimbursementType from "../../addSelectForms/AddReimbursementType";
import { float_validation, isValid, onCharactersAndNumbersWithSpaceChange, onNumberFloatCurrency, validate_alphaNumericId, validate_alphanumericwithspace, validate_alphanumericwithspace_optional, validate_charWithSpace, validate_emptyField, validates_Integer } from "../../../../components/Validation";
import LocalStorage from "../../../../utils/LocalStorage";
import deleteAlert from "../../../../assets/svg/placementPop.svg";
import LoadingButton from "../../../../components/customButton/LoadingButton";

export default function Reimbursement(props) {
    const { filterData, getAllPlacements, setOpenPopup } = props
    const [employeesList, setEmployeesList] = useState([])
    const [payType, setPayType] = useState([]);
    const [openReimbursementType, setOpenReimbursementType] = useState(false);
    const [error, setError] = useState({});
    const [openSubmit, setOpenSubmit] = useState(false);
    const [continueLoader, setContinueLoader] = useState(false);
    const [state, setState] = useState({
        request_id: LocalStorage.uid(),
        employee_id: '',
        reference_id: '',
        name: '',
        expense_type_id: '',
        date_of_expense: '',
        expense_effect_on: LocalStorage.getUserData().admin_login ? '' : 1,
        raised_amount: '',
        documents: [
            {
                document_name: '',
                document_url: '',
                new_document_id: ''
            }
        ],
        deduct_from: '',
        payment_mode: LocalStorage.getUserData().admin_login ? '' : 1,
        transaction_id: '',
        pay_date: '',
        payee_name: '',
        bank_name: '',
        bank_account_digits: '',
        pay_period_id: '',
        remarks: '',
    })
    const [deductState, setDeductState] = useState({
        id: '',
        reimbursement_approval_required: false,
        deduct_balance: false,
        off_cycle_reimbursement: false
    })
    const addToOptions = [
        {
            id: 1,
            value: 'Payroll'
        },
        {
            id: 2,
            value: 'Balance sheet'
        }
    ]

    const deductOptions = [
        {
            id: 1,
            value: 'Employee Balance'
        },
        {
            id: 2,
            value: 'Company Balance'
        }
    ]

    const paymentModes = [
        {
            id: 1,
            value: 'Payroll Cycle'
        },
        {
            id: 2,
            value: 'Off-payroll Cycle'
        }
    ]

    const [payPeriodList, setPayPeriodList] = useState([]);

    useEffect(() => {
        employeesListApi();
        payTypeApi();
        getDeductStatus();
        getPayPeriod(); // eslint-disable-next-line
    }, [])

    const getDeductStatus = () => {
        PayrollApi.getdeductBalanceStatus().then((res) => {
            if (res.data.statusCode === 1003) {
                setDeductState(res.data.data);
                if (res.data.data.off_cycle_reimbursement == false) {
                    state.payment_mode = 1
                    setState({ ...state });
                }
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const employeesListApi = () => {
        CommonApi.Employeesdropdown().then((res) => {
            if (res.data.statusCode === 1003) {
                setEmployeesList(res.data.data);
                if (LocalStorage.getUserData().admin_login == false) {
                    let resData = res.data.data
                    let id = resData.filter(i => i.id == LocalStorage.getUserData().login_id)
                    state['reference_id'] = id[0].reference_id
                    state['date_of_joining'] = id[0].date_of_joining
                    setState({ ...state })
                }
            }
        })
    }

    const getPayPeriod = () => {
        PayrollApi.payPeriodDropdown().then((res) => {
            if (res.data.statusCode === 1003) {
                setPayPeriodList(res.data.data);
            }
        })
    }

    const payTypeApi = () => {
        PayrollApi.getReimbursementDropdown().then((res) => {
            if (res.data.statusCode === 1003) {
                setPayType(res.data.data);
            }
        })
    }

    const changeHandler = (e) => {
        if (e.target.name == "expense_effect_on") {
            state.payment_mode = ''
            state.pay_period_id = ''
        }
        if (e.target.name == 'employee_id') {
            state[e.target.name] = e.target.value
            let id = employeesList.filter(i => i.id == e.target.value)
            state['reference_id'] = id[0].reference_id
            state['date_of_joining'] = id[0].date_of_joining
            setState({ ...state })
        } else if (e.target.name == 'raised_amount') {
            var value = e.target.value.trim();
            value = value.replace("$", "");
            if (value.length > 10) {
                const num = parseFloat(value);
                const isFloat = value.includes('.') || num % 1 !== 0;
                if (isFloat) {
                    state[e.target.name] = value;
                } else {
                    if (value.length === 11) {
                        if (value.slice(-1) === ".") {
                            state[e.target.name] = value;
                        } else {
                            return false;
                        }
                    }
                }
            }
            else {
                state[e.target.name] = value;
            }
            setState({ ...state });
        } else if (e.target.name == 'deduct_from') {
            state[e.target.name] = e.target.value
            state['expense_effect_on'] = ''
            if (e.target.value == 1) {
                state['expense_effect_on'] = 1
            }
            setState({ ...state })
        }
        else {
            state[e.target.name] = e.target.value
            setState({ ...state })
        }
        handleValidate(e)
    }

    const handleValidate = (e) => {
        let input = e.target
        switch (input.name || input.tagName) {
            case "name":
                error.name = validate_charWithSpace(input.value)
                break;
            case "employee_id":
                error.employee_id = validate_emptyField(input.value)
                break;
            case "expense_type_id":
                error.expense_type_id = validate_emptyField(input.value)
                break;
            case "date_of_expense":
                error.date_of_expense = validate_emptyField(input.value)
                break;
            case "deduct_from":
                error.deduct_from = validate_emptyField(input.value)
                break;
            case "expense_effect_on":
                error.expense_effect_on = validate_emptyField(input.value)
                break;
            case "raised_amount":
                error.raised_amount = float_validation(input.value);
                if (error.raised_amount == '' && parseInt(input.value) <= 0) {
                    error.raised_amount = 'The raise amount should be greater than 0'
                }
                break;
            case "payment_mode":
                error.payment_mode = validate_emptyField(input.value)
                break;
            case "pay_date":
                error.pay_date = validate_emptyField(input.value)
                break;
            case "transaction_id":
                error.transaction_id = validate_alphaNumericId(input.value)
                break;
            case "payee_name":
                error.payee_name = validate_charWithSpace(input.value)
                break;
            case "bank_name":
                error.bank_name = validate_alphanumericwithspace(input.value)
                break;
            case "bank_account_digits":
                error.bank_account_digits = validates_Integer(input.value)
                break;
            case "remarks":
                error.remarks = validate_alphanumericwithspace_optional(input.value)
                break;
            case "pay_period_id":
                error.pay_period_id = validate_emptyField(input.value)
                break;
            default:
                break;
        }
        setError({ ...error })
    }

    const dateHandler = (e, name) => {
        console.log(e, "e");
        console.log(name, "name");

        let date = e == null ? '' : e.$d // eslint-disable-next-line
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        setState({
            ...state,
            [name]: date == '' ? '' : moment(date).format(dateFormat())
        })
        handleValidate(event);
    }

    const validateAll = () => {
        const { employee_id, name, expense_type_id, date_of_expense, expense_effect_on, raised_amount, deduct_from, payment_mode, transaction_id, pay_date, payee_name, bank_name, bank_account_digits, remarks, pay_period_id } = state
        let errors = {}
        const dateOfJoining =  moment(moment(state.date_of_joining).format(dateFormat()), dateFormat());
        const today = moment(moment().format(dateFormat()), dateFormat());
        const givenDate = moment(date_of_expense, dateFormat());
        errors.employee_id = !LocalStorage.getUserData().admin_login ? '' : validate_emptyField(employee_id);
        errors.name = validate_charWithSpace(name);
        errors.expense_type_id = validate_emptyField(expense_type_id);
        errors.date_of_expense = givenDate.isBetween(dateOfJoining, today, null, '[]') ? validate_emptyField(date_of_expense) : 'Invalid date of Expense, Date must be between Date of Joining and Current date.' ;
        errors.deduct_from = !LocalStorage.getUserData().admin_login ? '' : deductState.deduct_balance ? validate_emptyField(deduct_from) : '';
        errors.expense_effect_on = !LocalStorage.getUserData().admin_login ? '' : validate_emptyField(expense_effect_on);
        errors.raised_amount = float_validation(raised_amount) == "" ? parseInt(raised_amount) <= 0 ? "The raise amount should be greater than 0" : "" : float_validation(raised_amount);
        errors.payment_mode = !LocalStorage.getUserData().admin_login ? '' : state.expense_effect_on == 1 ? validate_emptyField(payment_mode) : '';
        errors.pay_date = !LocalStorage.getUserData().admin_login ? '' : state.payment_mode == 2 ? validate_emptyField(pay_date) : '';
        errors.transaction_id = !LocalStorage.getUserData().admin_login ? '' : state.payment_mode == 2 ? validate_emptyField(transaction_id) : '';
        errors.payee_name = !LocalStorage.getUserData().admin_login ? '' : state.payment_mode == 2 ? validate_charWithSpace(payee_name) : '';
        errors.bank_name = !LocalStorage.getUserData().admin_login ? '' : state.payment_mode == 2 ? validate_alphanumericwithspace(bank_name) : '';
        errors.bank_account_digits = !LocalStorage.getUserData().admin_login ? '' : state.payment_mode == 2 ? validates_Integer(bank_account_digits) : '';
        errors.remarks = validate_alphanumericwithspace_optional(remarks);
        errors.pay_period_id = state.payment_mode == 1 ? validate_emptyField(pay_period_id) : '';
        setError({ ...errors })
        return errors;
    }

    const cancelButton = () => {
        setOpenPopup(false);
        setState({
            request_id: LocalStorage.uid(),
            employee_id: '',
            reference_id: '',
            name: '',
            expense_type_id: '',
            date_of_expense: '',
            expense_effect_on: LocalStorage.getUserData().admin_login ? '' : 1,
            raised_amount: '',
            documents: [
                {
                    document_name: '',
                    document_url: '',
                    new_document_id: ''
                }
            ],
            deduct_from: LocalStorage.getUserData().admin_login ? 1 : '',
            payment_mode: LocalStorage.getUserData().admin_login ? '' : 1,
            transaction_id: '',
            pay_date: '',
            payee_name: '',
            bank_name: '',
            bank_account_digits: '',
            pay_period_id: '',
            remarks: '',
        })
        setError({});
    }

    const createReimbursement = () => {
        setContinueLoader(true);
        state['entity_type'] = 1
        state['deduct_balance'] = deductState.deduct_balance
        state['off_cycle_reimbursement'] = deductState.off_cycle_reimbursement
        if (LocalStorage.getUserData().admin_login == false) {
            state['employee_id'] = LocalStorage.getUserData().login_id
        }
        if (!LocalStorage.getUserData().admin_login) {
            state['deduct_from'] = 1
        }
        PayrollApi.storePayroll(state).then((res) => {
            if (res.data.statusCode === 1003) {
                addSuccessMsg(res.data.message);
                setOpenPopup(false);
                getAllPlacements(filterData);
                setOpenSubmit(false);
            }
            else {
                setContinueLoader(false)
                addErrorMsg(res.data.message);
            }
        })
    }

    const submitHandler = () => {
        const errors = validateAll();
        if (isValid(errors)) {
            setOpenSubmit(true);
        } else {
            let s1 = { error }
            s1 = errors
            setError(s1);
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
        }
    }

    const handleApprove = (args) => {
        if (args == 'Approved') {
            createReimbursement();
        } else {
            setOpenSubmit(false);
        }
    }

    const uploadDocs = (e) => {
        if (e.target.files[0].type.split('/').some(r => ['png', 'jpg', 'jpeg', 'pdf'].includes(r))) {
            if (((e.target.files[0].size / (1024 * 1024)).toFixed(2)) < Number(25)) {
                const formData = new FormData();
                formData.append('files', e.target.files[0]);
                formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
                addLoader(true);
                CommonApi.documentUpload("expense-management", formData, LocalStorage.getAccessToken())
                    .then((res) => {
                        if (res.data.statusCode === 1003) {
                            removeLoader();
                            state.documents[0].new_document_id = res.data.data.id
                            state.documents[0].document_name = e.target.files[0].name
                            state.documents[0].document_url = res.data.data.document_url
                            setState({ ...state })
                        } else {
                            removeLoader();
                            addErrorMsg(res.data.message);
                        }
                    });
            } else {
                addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
            }
        } else {
            addErrorMsg("Please upload files in PNG or JPG or PDF format only.");
        }
    }

    const deleteDocs = () => {
        state.documents = [
            {
                new_document_id: '',
                document_name: '',
                document_url: ''
            }
        ]
        setState({ ...state })
    }


    return (
        <Grid container p={2}>
            <Grid item xs={12}>
                <Text mediumViewAmt>Add Reimbursement</Text>
            </Grid>
            {
                LocalStorage.getUserData().admin_login ?
                    <Grid container>
                        <Grid item xs={12} pt={2}>
                            <Box p={1}>
                                <Text smallBlack>Employee Details</Text>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box p={1}>
                                <Select
                                    name='employee_id'
                                    value={state.employee_id}
                                    label={'Employee Name'}
                                    options={employeesList}
                                    onChange={(e) => changeHandler(e)}
                                    error={error.employee_id ? <Text red>{error.employee_id ? error.employee_id : ''}</Text> : ''}
                                />
                                {error.employee_id ? <Text red>{error.employee_id ? error.employee_id : ''}</Text> : ''}
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box p={1}>
                                <Input
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        name: 'reference_id',
                                        value: state.reference_id
                                    }}
                                    disabled
                                    clientInput
                                    labelText={'Employee ID'}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box p={1}>
                                <Text smallBlack>Reimbursement Details</Text>
                            </Box>
                        </Grid>
                    </Grid>
                    : ''

            }


            <Grid item xs={6}>
                <Box p={1}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'name',
                            value: state.name,
                            inputProps: { maxLength: 50 }
                        }}
                        handleChange={(e) => changeHandler(e)}
                        onKeyPress={onCharactersAndNumbersWithSpaceChange}
                        clientInput
                        labelText={'Name'}
                        error={error.name ? <Text red>{error.name ? error.name : ''}</Text> : ''}
                    />
                    {error.name ? <Text red>{error.name ? error.name : ''}</Text> : ''}
                </Box>
            </Grid>

            {
                LocalStorage.getUserData().admin_login ?
                    <Grid item xs={6}>
                        <Box p={1}>
                            <Select
                                name='expense_type_id'
                                value={state.expense_type_id}
                                label={'Type'}
                                onChange={changeHandler}
                                options={payType}
                                buttonName='New Type'
                                buttonHandler={() => setOpenReimbursementType(true)}
                                showButton
                                error={error.expense_type_id ? <Text red>{error.expense_type_id ? error.expense_type_id : ''}</Text> : ''}
                            />
                            {error.expense_type_id ? <Text red>{error.expense_type_id ? error.expense_type_id : ''}</Text> : ''}
                        </Box>
                    </Grid>
                    :
                    <Grid item xs={6}>
                        <Box p={1}>
                            <Select
                                name='expense_type_id'
                                value={state.expense_type_id}
                                label={'Type'}
                                onChange={changeHandler}
                                options={payType}
                                error={error.expense_type_id ? <Text red>{error.expense_type_id ? error.expense_type_id : ''}</Text> : ''}
                            />
                            {error.expense_type_id ? <Text red>{error.expense_type_id ? error.expense_type_id : ''}</Text> : ''}
                        </Box>
                    </Grid>
            }


            <Grid item xs={6}>
                <Box p={1}>
                    <Date
                        labelText={'Date of Expense'}
                        name='date_of_expense'
                        height='54px'
                        value={state.date_of_expense}
                        onChange={(e) => dateHandler(e, 'date_of_expense')}
                        minDate={moment(state.date_of_joining).format(dateFormat())}
                        maxDate={moment().format(dateFormat())}
                        error={error.date_of_expense ? <Text red>{error.date_of_expense ? error.date_of_expense : ''}</Text> : ''}
                    />
                    {error.date_of_expense ? <Text red>{error.date_of_expense ? error.date_of_expense : ''}</Text> : ''}
                </Box>
            </Grid>

            {
                !LocalStorage.getUserData().admin_login || deductState.deduct_balance == false ? '' :
                    <Grid item xs={6}>
                        <Box p={1}>
                            <Select
                                name='deduct_from'
                                label={'Deduct from'}
                                value={state.deduct_from}
                                options={deductOptions}
                                onChange={changeHandler}
                                error={error.deduct_from ? <Text red>{error.deduct_from ? error.deduct_from : ''}</Text> : ''}
                            />
                            {error.deduct_from ? <Text red>{error.deduct_from ? error.deduct_from : ''}</Text> : ''}
                        </Box>
                    </Grid>
            }
            {
                LocalStorage.getUserData().admin_login ?
                    <Grid item xs={6}>
                        <Box p={1}>
                            <Select
                                name='expense_effect_on'
                                label={'Add to'}
                                value={state.expense_effect_on}
                                options={addToOptions}
                                disabled={state.deduct_from == 1}
                                onChange={changeHandler}
                                error={error.expense_effect_on ? <Text red>{error.expense_effect_on ? error.expense_effect_on : ''}</Text> : ''}
                            />
                            {error.expense_effect_on ? <Text red>{error.expense_effect_on ? error.expense_effect_on : ''}</Text> : ''}
                        </Box>
                    </Grid>
                    : ''}
            {
                !LocalStorage.getUserData().admin_login ? <Grid item xs={6}></Grid> : ''}
            <Grid item xs={6}>
                <Box p={1}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'raised_amount',
                            value: state.raised_amount
                        }}
                        onKeyPress={onNumberFloatCurrency}
                        handleChange={changeHandler}
                        clientInput
                        labelText={'Reimbursement Amount'}
                        error={error.raised_amount ? <Text red>{error.raised_amount ? error.raised_amount : ''}</Text> : ''}
                    />
                    {error.raised_amount ? <Text red>{error.raised_amount ? error.raised_amount : ''}</Text> : ''}
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box p={1}>
                    <FileInput
                        name='new_document_id'
                        FileName={state.documents[0].document_name}
                        handleChange={(e) => uploadDocs(e)}
                        label='Supporting Document (Optional)'
                        handleDelete={deleteDocs}
                        actionState={state.documents && state.documents.length > 0 && state.documents[0].document_name ? 1 : ''}
                        documentUrl={state.documents && state.documents.length > 0 && state.documents[0].document_url}
                    />
                    <Text greyLabel sx={{ padding: '5px 0px' }}>File size should not exceed 25mb</Text>
                </Box>
            </Grid>
            {
                state.expense_effect_on == 1 ?
                    <Grid item container>
                        <Grid item xs={6}>
                            <Box p={1}>
                                <Select
                                    name='payment_mode'
                                    value={state.payment_mode}
                                    onChange={changeHandler}
                                    label={'Payment Mode'}
                                    options={paymentModes}
                                    disabled={!LocalStorage.getUserData().admin_login ? true : deductState.off_cycle_reimbursement == false}
                                    error={error.payment_mode ? <Text red>{error.payment_mode ? error.payment_mode : ''}</Text> : ''}
                                />
                                {error.payment_mode ? <Text red>{error.payment_mode ? error.payment_mode : ''}</Text> : ''}
                            </Box>
                        </Grid>
                        {
                            state.payment_mode == 1 ?
                                <Grid item xs={6}>
                                    <Box p={1}>
                                        <Select
                                            name='pay_period_id'
                                            value={state.pay_period_id}
                                            onChange={changeHandler}
                                            label={'Payment Period'}
                                            options={payPeriodList}
                                            error={error.pay_period_id ? <Text red>{error.pay_period_id ? error.pay_period_id : ''}</Text> : ''}
                                        />
                                        {error.pay_period_id ? <Text red>{error.pay_period_id ? error.pay_period_id : ''}</Text> : ''}
                                    </Box>
                                </Grid> :
                                <Grid item xs={6}>
                                    <Box p={1}>
                                        <Date
                                            labelText={'Pay Date'}
                                            name='pay_date'
                                            value={state.pay_date}
                                            onChange={(e) => dateHandler(e, "pay_date")}
                                            height='54px'
                                            error={error.pay_date ? <Text red>{error.pay_date ? error.pay_date : ''}</Text> : ''}
                                        />
                                        {error.pay_date ? <Text red>{error.pay_date ? error.pay_date : ''}</Text> : ''}
                                    </Box>
                                </Grid>
                        }
                        {
                            state.payment_mode == 2 ?
                                <>
                                    <Grid item xs={12}>
                                        <Box p={1}>
                                            <Text smallBlack>Transaction Details</Text>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box p={1}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: "transaction_id",
                                                    value: state.transaction_id,
                                                    inputprops: { maxlength: 50 }
                                                }}
                                                handleChange={changeHandler}
                                                clientInput
                                                labelText={'Transaction ID'}
                                                error={error.transaction_id ? <Text red>{error.transaction_id ? error.transaction_id : ''}</Text> : ''}
                                            />
                                            {error.transaction_id ? <Text red>{error.transaction_id ? error.transaction_id : ''}</Text> : ''}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box p={1}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: "payee_name",
                                                    value: state.payee_name,
                                                    inputProps: { maxLength: 50 }
                                                }}
                                                handleChange={changeHandler}
                                                clientInput
                                                labelText={'Payee Name'}
                                                error={error.payee_name ? <Text red>{error.payee_name ? error.payee_name : ''}</Text> : ''}
                                            />
                                            {error.payee_name ? <Text red>{error.payee_name ? error.payee_name : ''}</Text> : ''}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box p={1}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: "bank_name",
                                                    value: state.bank_name,
                                                    inputProps: { maxLength: 50 }
                                                }}
                                                handleChange={changeHandler}
                                                clientInput
                                                labelText={'Bank Name'}
                                                error={error.bank_name ? <Text red>{error.bank_name ? error.bank_name : ''}</Text> : ''}
                                            />
                                            {error.bank_name ? <Text red>{error.bank_name ? error.bank_name : ''}</Text> : ''}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box p={1}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: "bank_account_digits",
                                                    value: state.bank_account_digits,
                                                    inputProps: { maxLength: 4 }
                                                }}
                                                handleChange={changeHandler}
                                                clientInput
                                                labelText={'Last Four Digits of Bank Account'}
                                                error={error.bank_account_digits ? <Text red>{error.bank_account_digits ? error.bank_account_digits : ''}</Text> : ''}
                                            />
                                            {error.bank_account_digits ? <Text red>{error.bank_account_digits ? error.bank_account_digits : ''}</Text> : ''}
                                        </Box>
                                    </Grid>
                                </> : ''
                        }
                    </Grid> : ''
            }



            <Grid item xs={12}>
                <Box p={1}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: "remarks",
                            value: state.remarks,
                            inputProps: { maxLength: 255 }
                        }}
                        handleChange={changeHandler}
                        clientInput
                        labelText={'Remarks (Optional)'}
                        error={error.remarks ? <Text red>{error.remarks ? error.remarks : ''}</Text> : ''}
                    />
                    {error.remarks ? <Text red>{error.remarks ? error.remarks : ''}</Text> : ''}
                </Box>
            </Grid>
            <Grid item container xs={12} justifyContent={'flex-end'}>
                <Stack direction={'row'}>
                    <Box p={1}>
                        <Button finishLater onClick={cancelButton}>Cancel</Button>
                    </Box>
                    <Box p={1}>
                        <Button saveVerySmall onClick={submitHandler}>Save</Button>
                    </Box>
                </Stack>
            </Grid>
            <ReusablePopup openPopup={openReimbursementType} white iconHide fixedWidth setOpenpopup={setOpenReimbursementType}>
                <AddReimbursementType open={openReimbursementType} closePopup={setOpenReimbursementType} payTypeApi={payTypeApi} />
            </ReusablePopup>
            <ReusablePopup openPopup={openSubmit} white iconHide fixedWidth setOpenpopup={setOpenSubmit}>
                <Grid container p={2} spacing={2} textAlign='center'>
                    <Grid item xs={12}>
                        <img src={deleteAlert} alt='delete' />
                    </Grid>
                    <Grid item xs={12}>
                        <Text popupHead1>Are you Sure?</Text>
                    </Grid>
                    <Grid item xs={12}>
                        <Text mediumOverView>Reimbursements once added can’t be edited. Do you really wish to continue?</Text>
                    </Grid>
                    <Grid item xs={12} mt={1}>
                        <Box display='flex' flexDirection='row' gap={2} justifyContent='center'>
                            <Button greyBorder sx={{ width: '140px !important' }} onClick={() => handleApprove('Rejected')}>Cancel</Button>
                            <LoadingButton loading={continueLoader} saveBtn400 sx={{ width: "140px !important" }} onClick={() => handleApprove('Approved')}>Yes,Continue</LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </ReusablePopup>
        </Grid>
    )
}