import React, { useState, useEffect, useRef } from 'react';
import ArrowLeft from '../../../../assets/svg/fi_arrow-left.svg'
import raisedImage from "../../../../assets/images/raised.png"
import approvedImage from "../../../../assets/images/deduction.png"
import approvalImage from "../../../../assets/images/paidBalancesheet.png"
import totalImage from "../../../../assets/images/reimburesementImg.png"
import ExpenseManagement from "../../../../apis/admin/payroll/ExpenseManagement";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { Box, Stack, Grid, Checkbox, SwipeableDrawer, Divider, Backdrop, Badge, Chip, FormControlLabel, Slider, IconButton, Avatar } from '@mui/material';
import { styled } from "@mui/material/styles";
import Skeleton from '@mui/material/Skeleton';
import NewDownloadcloud from '../../../../assets/svg/download-cloud-new.svg';
import { addErrorMsg, addLoader, addLoaderPlanExpired, BlackToolTip, dateFormat, downloadExcel, NoDataFound, NoPermission, removeExpiredLoader, removeLoader } from '../../../../utils/utils';
import { useLocation, useNavigate } from "react-router-dom";
import Text from '../../../../components/customText/Text';
import Button from '../../../../components/customButton/Button';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/cross.svg';
import { ReactComponent as CheckedIcon } from '../../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../../assets/svg/CheckedBorderIcon.svg';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import LocalStorage from '../../../../utils/LocalStorage';
import infoCircle from '../../../../assets/images/info-circle.png';
import Loader from '../../../../assets/gif/japfuLoader.gif';
import FilterListIcon from '@mui/icons-material/FilterList';
import PlacementDashboardStyles from '../../placements/newDashboard/PlacementDashboardStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import nofilterplacement from '../../../../assets/svg/nofilterplacement.svg';
import CircleIcon from '@mui/icons-material/Circle';
import moment from 'moment';
import { domain } from '../../../../config/Domain';
import DashboardAPI from '../../../../apis/admin/DashboardAPI';
import Input from '../../../../components/input/Input';
import PayrollApi from '../../../../apis/admin/payroll/ExpenseManagement';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import CurrentBalancePopup from './CurrentBalance';
import Date from '../../../../components/datePicker/Date';
import { validate_emptyField } from '../../../../components/Validation';
import { cancelRed } from '../../../../theme';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));


export default function BalanceSheetIndex() {
    const navigate = useNavigate();
    const location = useLocation()
    const locationData = location?.state?.data
    const paymentCycleList = [
        {
            id: 1,
            value: "Payroll Cycle"
        },
        {
            id: 2,
            value: "Off - Payroll Cycle"
        }
    ]
    const cardsJson = [
        {
            name: 'Total Earnings',
            imgPath: raisedImage,
            mainKey: 'total_earning',
            color: '#E7F2FD'
        },
        {
            name: 'Total Deductions',
            imgPath: approvedImage,
            mainKey: 'total_deduction',
            color: '#EAF9EB'
        },
        {
            name: 'Total Paid',
            imgPath: approvalImage,
            mainKey: 'total_salary',
            color: '#F3F0FF'
        },
        {
            name: ' Total Reimbursements',
            imgPath: totalImage,
            mainKey: 'total_reimbursement',
            color: '#FFF0F0'
        }
    ]
    const [cardDetails, setCardDetails] = useState([]);
    const [currentOpen, setCurrentOpen] = useState(false);
    const [type, setType] = useState('');
    const [currentBalanceRow, setCurrentBalanceRow] = useState(false);

    useEffect(() => {
        cardDetailsApi()
        // eslint-disable-next-line
    }, [])
    const cardDetailsApi = (args) => {
        let emp_id = LocalStorage.getUserData().admin_login ? locationData?.employee_id : LocalStorage.getUserData().login_id
        ExpenseManagement.getCarDetailBalanceSheet(emp_id).then((res) => {
            if (res.data.statusCode === 1003) {
                setCardDetails(res.data.data[0]);
            }
        })
    }


    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = PlacementDashboardStyles();
    const [balanceData, setBalanceData] = useState([]);
    const [pagination, setPagination] = useState({ total: "6" });
    const [loading, setLoading] = useState(false); // eslint-disable-next-line
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [filterData, setFilterData] = useState({
        limit: 10,
        page: 1,
        search: "",
        sort_column: "created_at",
        sort_order: "desc",
        payment_mode: [],
        deducted_or_added_to: [],
        pay_period_from: "",
        pay_period_to: "",
        pay_check_date_from: "",
        pay_check_date_to: "",
        balance_impact_from: "",
        balance_impact_to: "",
        current_balance_from: "",
        current_balance_to: "",
        balance_impact: [0, 0],
        current_balance: [0, 0],
        employee_id: LocalStorage.getUserData().admin_login ? locationData.employee_id : LocalStorage.getUserData().login_id
    })

    const accordianHandler = (e, item, type) => {
        e.stopPropagation();
        setCurrentBalanceRow(item);
        setType(type)
        setCurrentOpen(true)
    }

    const deductedFromDropdown = [
        {
            "id": 2,
            "value": "Balancesheet"
        },
        {
            "id": 1,
            "value": "Payroll"
        }
    ]

    const [filterState, setFilterState] = useState({
        start_date_from: "",
        start_date_to: "",
        end_date_from: "",
        end_date_to: "",
        deductionType: [],
        status: [],
        recruiter: [],
        payment_mode: [],
        deducted_or_added_to: [],
        pay_period_from: "",
        pay_period_to: "",
        pay_check_date_from: "",
        pay_check_date_to: "",
        balance_impact_from: "",
        balance_impact_to: "",
        current_balance_from: "",
        current_balance_to: "",
        limit: 10,
        page: 1
    })
    const [datesError, setDatesError] = useState({
        start_date_from: "",
        start_date_to: "",
        end_date_from: "",
        end_date_to: "",
        pay_period_from: "",
        pay_period_to: "",
        pay_check_date_from: "",
        pay_check_date_to: "",
        balance_impact_from: "",
        balance_impact_to: "",
        approved_amount: "",
        reason_for_rejection: ""
    })
    const [openBacKLoader, setOpenBacKLoader] = useState(false);
    const [drawer, setDrawer] = useState(false);

    useEffect(() => {
        if ((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) {
            getAllPlacements(filterData)
        }
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        getPlanCheck();
        // eslint-disable-next-line
    }, [])


    const getPlanCheck = () => {
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        DashboardAPI.planCheck(data).then((res) => {
            if (res.data.statusCode === 1003) {
                let data = LocalStorage.getUserData()
                data.plan_name = res.data.plan_name
                data.plan_not_set = res.data.plan_not_set
                LocalStorage.setDateFormat(res.data.date_format)
                LocalStorage.setUserData(data);
                LocalStorage.setPlanCheckLocal(res.data)
                if (res.data.plan_not_set) {
                    navigate('/billing', { state: { renew: true } })
                }
                if (res.data.plan_expired) {
                    if (location.pathname != '/billing' || location.pathname != '/plan-status') {
                        addLoaderPlanExpired()
                    }
                    else {
                        removeExpiredLoader()
                    }
                }
            }
        })
    }

    const handleDownloadExport = () => {
        addLoader(true);
        filterData.request_id = LocalStorage.uid()
        PayrollApi.downLoadBalanceSheetExcel(filterData).then((response) => {
            removeLoader();
            if (response?.data instanceof Blob) {
                const blob = response.data;
                const formattedDate = moment().format('DDMMYYhhmmssSSS');
                let filename = `BalanceSheet_${formattedDate}.xlsx`
                downloadExcel(blob, filename)
            }
            else {
                addErrorMsg(response.data.message)
            }
        });
    }


    const getAllPlacements = (filter) => {
        if (balanceData.length < 6) {
            setLoading(true);
        }
        ExpenseManagement.getBalanceSheetIndex(filter).then((response) => {
            removeLoader();
            setOpenBacKLoader(false);
            if (response.data.statusCode == 1003) {
                setDrawer(null);
                setLoading(false);
                setBalanceData(response.data.data);
                setPagination({ total: response.data.pagination.total });
            } else {
                setLoading(false);
                if (response.data.message == `You don't have access to perform this action. Please contact admin`) {

                }
                else {
                    addErrorMsg(response.data.message);
                }
            }
        })

    }

    const handleDeleteChip = (id, name) => {
        if (name === "pay_period" || name === "balance_impact" || name === "pay_check_date" || name === "current_balance") {
            const updatedFilterState = { ...filterState }; // Create a copy of the filterState object

            // Update the start_date or end_date fields
            updatedFilterState[name + "_from"] = "";
            updatedFilterState[name + "_to"] = "";

            // Update the state with the modified filterState object
            setFilterState(updatedFilterState);
        } else {
            const newFilterState = { ...filterState };
            const updatedArray = newFilterState[name].filter(item => item !== id);
            newFilterState[name] = updatedArray;
            setFilterState(newFilterState);
        }
    };

    const handleChangeCheckBox = (e) => {
        // e.preventDefault();
        const { name, value } = e.target;
        const updatedArray = [...filterState[name]]; // Create a new array
        var finalValue = value
        if (e.target.name != "status") {
            finalValue = Number(value)
        }
        const index = updatedArray.findIndex(item => item === finalValue);
        if (index !== -1) {
            updatedArray.splice(index, 1); // Remove item if it exists
        } else {
            updatedArray.push(finalValue); // Add item if it doesn't exist
        }

        setFilterState(prevState => ({
            ...prevState,
            [name]: updatedArray // Update the state with the new array
        }));
    }

    const clearAllFilter = () => {
        datesError.start_date_from = '';
        datesError.start_date_to = '';
        datesError.end_date_from = '';
        datesError.end_date_to = '';
        setDatesError({ ...datesError });
        setFilterState(prevState => ({
            ...prevState,
            payment_mode: [] // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            deducted_or_added_to: [] // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            pay_period_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            pay_period_to: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            pay_check_date_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            pay_check_date_to: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            balance_impact_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            balance_impact_to: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            current_balance_from: '' // Update the state with the new array
        }));
        setFilterState(prevState => ({
            ...prevState,
            current_balance_to: '' // Update the state with the new array
        }));
    }

    const cancelFilter = () => {
        setDrawer(false);
    }

    const handleListItemClick = (param) => {
        setSelectedFilter(param);
    }

    const handleApplyFilters = () => {
        if (filterState.pay_period_from != "" && filterState.pay_period_to == "") {
            datesError.pay_period_to = "Please select the To date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid From/To dates");
            return false;
        }
        if (filterState.pay_period_from == "" && filterState.pay_period_to != "") {
            datesError.pay_period_from = "Please select the from date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid From/To dates");
            return false;
        }
        if (filterState.pay_period_from !== "" && filterState.pay_period_to !== "") {
            if (moment(filterState.pay_period_from, dateFormat()).isSameOrBefore(moment(filterState.pay_period_to, dateFormat()))) {
                filterData.pay_period_from = filterState.pay_period_from;
                filterData.pay_period_to = filterState.pay_period_to;
            } else {
                addErrorMsg("Please select valid From/To dates");
                return false;
            }
        } else {
            filterData.pay_period_from = '';
            filterData.pay_period_to = '';
        }
        if (filterState.pay_check_date_from != "" && filterState.pay_check_date_to == "") {
            datesError.pay_check_date_to = "Please select the To date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid From/To dates");
            return false;
        }
        if (filterState.pay_check_date_from == "" && filterState.pay_check_date_to != "") {
            datesError.pay_check_date_from = "Please select the from date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid From/To dates");
            return false;
        }
        if (filterState.pay_check_date_from !== "" && filterState.pay_check_date_to !== "") {
            if (moment(filterState.pay_check_date_from, dateFormat()).isSameOrBefore(moment(filterState.pay_check_date_to, dateFormat()))) {
                filterData.pay_check_date_from = filterState.pay_check_date_from;
                filterData.pay_check_date_to = filterState.pay_check_date_to;
            } else {
                addErrorMsg("Please select valid From/To dates");
                return false;
            }
        } else {
            filterData.pay_check_date_from = '';
            filterData.pay_check_date_to = '';
        }
        if (filterState.balance_impact_from != "" && filterState.balance_impact_to == "") {
            datesError.balance_impact_to = "Please select the max amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if ((filterState.balance_impact_from == "" && filterState.balance_impact_from != 0) && filterState.balance_impact_to != "") {
            datesError.balance_impact_from = "Please select the min amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if (filterState.balance_impact_from !== "" && filterState.balance_impact_to !== "") {
            if (filterState.balance_impact_from < filterState.balance_impact_to) {
                filterData.balance_impact_from = filterState.balance_impact_from;
                filterData.balance_impact_to = filterState.balance_impact_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.balance_impact_from = '';
            filterData.balance_impact_to = '';
        }
        if (filterState.current_balance_from != "" && filterState.current_balance_to == "") {
            datesError.current_balance_to = "Please select the max amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if ((filterState.current_balance_from == "" && filterState.current_balance_from != 0) && filterState.current_balance_to != "") {
            datesError.current_balance_from = "Please select the min amount.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select valid min/max amount");
            return false;
        }
        if (filterState.current_balance_from !== "" && filterState.current_balance_to !== "") {
            if (filterState.current_balance_from < filterState.current_balance_to) {
                filterData.current_balance_from = filterState.current_balance_from;
                filterData.current_balance_to = filterState.current_balance_to;
            } else {
                addErrorMsg("Please select valid min/max amount");
                return false;
            }
        } else {
            filterData.current_balance_from = '';
            filterData.current_balance_to = '';
        }
        filterData.payment_mode = filterState.payment_mode;
        filterData.deducted_or_added_to = filterState.deducted_or_added_to;
        filterData.balance_impact = [filterState.balance_impact_from, filterState.balance_impact_to]
        filterData.current_balance = [filterState.current_balance_from, filterState.current_balance_to]
        if (filterData.balance_impact.length === 2 && filterData.balance_impact[0] === "" && filterData.balance_impact[1] === "") {
            filterData.balance_impact = [0, 0]; // Change to an empty array
        }
        if (filterData.current_balance.length === 2 && filterData.current_balance[0] === "" && filterData.current_balance[1] === "") {
            filterData.current_balance = [0, 0]; // Change to an empty array
        }
        setFilterData({ ...filterData });
        addLoader(true)
        getAllPlacements({ ...filterData, limit: 10, page: 1 });
    }
    const loadMoreData = () => {
        // addLoader(true);
        filterData.limit = Number(filterData.limit) + 6;
        setFilterData({ ...filterData });
        getAllPlacements(filterData);

    }

    const handleOpenDrawer = () => {
        setDrawer("filter");
        if (filterData.pay_period_from !== "" && filterData.pay_period_to !== "") {
            filterState.pay_period_from = filterData.pay_period_from;
            filterState.pay_period_to = filterData.pay_period_to;
            setSelectedFilter(2);
        } else {
            filterState.pay_period_from = '';
            filterState.start_date_to = '';
        }
        if (filterData.pay_check_date_from !== "" && filterData.pay_check_date_to !== "") {
            filterState.pay_check_date_from = filterData.pay_check_date_from;
            filterState.pay_check_date_to = filterData.pay_check_date_to;
            setSelectedFilter(4);
        } else {
            filterState.pay_check_date_from = '';
            filterState.pay_check_date_to = '';
        }
        if (filterData.balance_impact_from !== "" && filterData.balance_impact_to !== "") {
            filterState.balance_impact_from = filterData.balance_impact_from;
            filterState.balance_impact_to = filterData.balance_impact_to;
            setSelectedFilter(5);
        } else {
            filterState.balance_impact_from = '';
            filterState.balance_impact_to = '';
        }
        if (filterData.current_balance_from !== "" && filterData.current_balance_to !== "") {
            filterState.current_balance_from = filterData.current_balance_from;
            filterState.current_balance_to = filterData.current_balance_to;
            setSelectedFilter(6);
        } else {
            filterState.current_balance_from = '';
            filterState.current_balance_to = '';
        }
        if (filterData.payment_mode.length > 0) {
            setSelectedFilter(1);
        }
        if (filterData.deducted_or_added_to.length > 0) {
            setSelectedFilter(3);
        }
        if (filterData.start_date_from == "" && filterData.start_date_to == "" && filterData.end_date_from == "" && filterData.end_date_to == "" && filterData.timesheet_cycle.length == 0 && filterData.deductionType.length == 0 && filterData.recruiter.length == 0) {
            setSelectedFilter(null);
        }
        filterState.payment_mode = filterData.payment_mode;
        filterState.deducted_or_added_to = filterData.deducted_or_added_to;
        setFilterState({ ...filterState });
        datesError.start_date_to = "";
        datesError.end_date_to = "";
        setDatesError({ ...datesError })
    }

    const scrollBox = useRef(null);

    const handleScroll = () => {
        const { current } = scrollBox;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                if (Number(pagination.total) >= filterData.limit) {
                    setOpenBacKLoader(true);
                    loadMoreData();
                }
            } else {
            }
        }
    };

    const handleSliderChangeRaised = (event, newValue) => {
        filterState.balance_impact_from = newValue[0]
        filterState.balance_impact_to = newValue[1]
        setFilterState({ ...filterState })
    };

    const handleSliderChangeCurrent = (event, newValue) => {
        filterState.current_balance_from = newValue[0]
        filterState.current_balance_to = newValue[1]
        setFilterState({ ...filterState })
    };

    const handleInputChange = (index, value, name) => {
        if (name) {
            if (index == 0) {
                filterState[name] = value ? Number(value) : '';
            }
            else {
                filterState[name] = value ? Number(value) : '';
            }
        }
        else {
            if (index == 0) {
                filterState.balance_impact_from = value ? Number(value) : '';
            }
            else {
                filterState.balance_impact_to = value ? Number(value) : '';
            }
        }

        setFilterState({ ...filterState })
    };

    const handleFilterDrawerClose = () => {
        filterState.payment_mode = []
        filterState.deducted_or_added_to = [];
        filterState.pay_period_from = '';
        filterState.pay_period_to = '';
        filterState.pay_check_date_from = '';
        filterState.pay_check_date_to = '';
        filterState.balance_impact_from = '';
        filterState.balance_impact_to = '';
        setFilterState({ ...filterState });
        setDrawer(null);
    }

    const dateChange = (e, keyName) => {
        let date = e == null ? '' : e.$d
        let event = { target: { name: keyName, value: date } }
        filterState[keyName] = date == '' ? '' : moment(date).format(dateFormat());
        setFilterState({ ...filterState }, handleValidate(event, keyName));
    }

    const handleValidate = (e, name) => {
        const input = e.target
        switch (input.name || input.tagName) {
            case name:
                datesError[name] = validate_emptyField(input.value)
                break;
            default:
                break;
        }
        setDatesError({ ...datesError });
    }

    return (
        <Grid container pl={'130px'} pr={'65px'}>
            <Grid item lg={12} pb={2}>
                <Box display={'flex'} alignItems={'center'} gap={1}>
                    {
                        LocalStorage.getUserData().admin_login ?
                            <IconButton disableRipple onClick={() => { navigate('/balanceSheet') }}>
                                <img src={ArrowLeft} alt='ArrowLeft'></img>
                            </IconButton> : ''
                    }

                    <Text blackHeader600 >Balance Sheet</Text>
                </Box>
            </Grid>
            <Grid item container lg={12} gap={2}>
                <Grid item lg={3.8} md={3.6} alignContent={'center'}>
                    <Box p={1} minHeight={'240px'} sx={{ background: '#FAFAFA', borderRadius: '12px ' }}>
                        <Box p={2} display={'flex'} flexDirection={'row'} minHeight={'110px'}>
                            <Grid item xl={4} lg={4} xs={6} md={6} pl={2} pt={1}>
                                <Avatar
                                    alt={"A"}
                                    src={cardDetails?.profile_picture_url}
                                    sx={{ width: 75, height: 75, }}
                                />
                            </Grid>
                            <Grid item xl={8} lg={8} xs={6} md={6} pt={2.5}>
                                <Text mediumBlack sx={{ paddingBottom: '10px', textTransform: 'capitalize' }}>
                                    <BlackToolTip arrow title={cardDetails?.employee_name}>{cardDetails?.employee_name ? cardDetails?.employee_name.length > 16 ? cardDetails?.employee_name.slice(0, 16) + '...' : cardDetails?.employee_name : "--"}
                                    </BlackToolTip>
                                </Text>
                                <Text mediumLabel>{cardDetails?.employee_reference_id} {cardDetails?.payment_mode}</Text>
                            </Grid>
                        </Box>
                        <Divider />
                        <Box p={2} pt={4} display={'flex'} flexDirection={'row'} minHeight={'110px'} alignContent={'center'}>
                            <Grid item xs={12} pt={2.5} pl={2}>
                                <Text mediumBlack>Current Balance</Text>
                            </Grid>
                            <Grid item xs={12} pt={2}>
                                <Text largeOrange22>$ {cardDetails?.current_balance}</Text>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <Grid item container lg={8} md={8} gap={1}>
                    {
                        cardsJson.map((item) => (
                            <Grid item container xs={5.9} sx={{ backgroundColor: item.color, borderRadius: '12px ' }} alignContent={'center'} >
                                <Grid item xs={12}>
                                    <Box p={2} minHeight={'110px'}>
                                        <Grid item xs={12}>
                                            <Text blackHeader1 sx={{ fontSize: '13.5px !important' }}>{item.name}</Text>
                                        </Grid>
                                        <Box p={2} height={'2vh'}></Box>
                                        <Grid item container xs={12}>
                                            <Grid item xs={4}>
                                                <Stack direction={'row'}>
                                                    <img src={item.imgPath} alt={item.name} />
                                                    {
                                                        item.mainKey == "total_reimbursement" ?
                                                            <Text largeBlack22>&nbsp; {cardDetails['reimbursement_count'] ? cardDetails['reimbursement_count'] : '0'}</Text>
                                                            : ''
                                                    }
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={8} container justifyContent={'flex-end'}>
                                                <Text largeBlack22>$&nbsp; {cardDetails[item.mainKey] ? cardDetails[item.mainKey] : '0'}</Text>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Box className={classes.flexBox}>

                    <Box sx={{ width: "100%" }} >
                        <Box style={{ padding: "20px 10px 10px 10px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div>
                                    <Text blackHeader600>Transaction History</Text>
                                </div>
                                {
                                    ((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) ?
                                        <div>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "16px", paddingRight: "5px" }}>
                                                <Box display={"flex"} justifyContent={"center"}>
                                                    <Text offBoardBodyFont>
                                                        Count
                                                        <HtmlTooltip
                                                            placement="bottom"
                                                            arrow
                                                            title={
                                                                <React.Fragment>
                                                                    <Box>
                                                                        <Typography className={classes.profileTooltipText}>
                                                                            Total Employee count is <br />based on the filters applied
                                                                        </Typography>
                                                                    </Box>
                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <img src={infoCircle} alt="infoCircle" height={"17px"} style={{ marginLeft: "3px", marginBottom: "-4px", cursor: "pointer" }} />
                                                        </HtmlTooltip>
                                                        {` - ${pagination ? pagination.total ? pagination.total : 0 : 0} Transactions`}</Text>
                                                </Box>
                                                <button title="Export" type="button" className={classes.borderButton}
                                                    onClick={() => handleDownloadExport()}
                                                >
                                                    <img src={NewDownloadcloud} alt="Userplus" />
                                                </button>
                                                <button title="Filter"
                                                    type="button"
                                                    className={classes.borderButton}
                                                    onClick={() => { handleOpenDrawer() }}>
                                                    <Badge badgeContent={filterData.payment_mode.length + filterData.deducted_or_added_to.length + ((filterData.pay_check_date_from !== "" && filterData.pay_check_date_to !== "") && 1) + ((filterData.pay_period_from !== "" && filterData.pay_period_to !== "") && 1) + ((filterData.balance_impact_from !== "" && filterData.balance_impact_to !== "") && 1) + ((filterData.current_balance_from !== "" && filterData.current_balance_to !== "") && 1)} color="error">
                                                        <FilterListIcon sx={{ color: (filterData.payment_mode.length > 0 || filterData.deducted_or_added_to.length > 0 || (filterData.pay_check_date_from !== "" && filterData.pay_check_date_to !== "") || (filterData.pay_period_from !== "" && filterData.pay_period_to !== "") || (filterData.balance_impact_from !== "" && filterData.balance_impact_to !== "") || (filterData.current_balance_from !== "" && filterData.current_balance_to !== "")) ? "#2BEA2B" : "" }} />
                                                    </Badge>
                                                </button>
                                            </div>
                                        </div>
                                        : ""
                                }
                            </div>

                        </Box>
                        {
                            ((LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login) || (rolePermission !== "" && rolePermission.some(item => item.slug == "payroll_view" && item.is_allowed == true))) ?
                                <Box style={{ padding: "10px" }}>

                                    <Box sx={{
                                        display: "flex", justifyContent: "space-between", fontSize: "14px", fontWeight: "600", alignItems: "center", borderBottom: "1px solid #F3EFEF", padding: "16px 12px",
                                        "@media (min-width: 200px) and (max-width: 1400px)": {
                                            font: "12px Quicksand",
                                            fontWeight: "600",
                                            paddingTop: '1px'
                                        },
                                    }} >
                                        <Grid container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                            <Grid item lg={2} md={2} sm={2} xs={2} className={classes.headerEndDate} >Payment Mode</Grid>
                                            <Grid item lg={2} md={2} sm={2} xs={2} className={classes.headerEndDate} >Pay Period</Grid>
                                            <Grid item lg={2} md={2} sm={2} xs={2} className={classes.headerEndDate} >Deducted / Added to</Grid>
                                            <Grid item lg={2} md={2} sm={2} xs={2} className={classes.headerEndDate} >Pay / Check Date</Grid>
                                            <Grid item lg={2} md={2} sm={2} xs={2} className={classes.headerEndDate} >Balance Impact</Grid>
                                            <Grid item lg={2} md={2} sm={2} xs={2} className={classes.headerEndDate} >Current Balance</Grid>
                                        </Grid>
                                    </Box>

                                    {
                                        loading ?
                                            [1, 2, 3, 4].map((item) => (
                                                <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                                    <Box sx={{ width: "59%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                                        <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                            <Box>
                                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                            </Box>
                                                            <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ width: "41%", display: "flex", alignItems: "center", gap: 2 }}>
                                                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                        </Box>
                                                    </Box>
                                                </Stack>
                                            )) :
                                            <>
                                                {
                                                    balanceData.length > 0 ?
                                                        <Box sx={{
                                                            height: "35vh", overflowY: 'scroll', alignItems: 'start', justifyContent: 'start',
                                                            paddingLeft: '5px', paddingRight: '5px',
                                                            "@media (min-height: 850px)": {
                                                                height: '73.5vh'
                                                            }
                                                        }} ref={scrollBox} onScroll={handleScroll}>
                                                            {
                                                                balanceData.map((place, key) => (
                                                                    <Accordion
                                                                        key={key}
                                                                        className={classes.newCustomAccordion}
                                                                    >
                                                                        <AccordionSummary
                                                                            aria-controls="panel1bh-content"
                                                                            id="panel1bh-header"
                                                                            className={classes.AccordionSummary}
                                                                        >
                                                                            <Grid container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                                                                <Grid item lg={2} md={2} sm={2} xs={2} className={classes.headerNames} pl={3}><Text mediumBlack14>{place.payment_cycle}</Text></Grid>
                                                                                <Grid item lg={2} md={2} sm={2} xs={2} className={classes.headerNames} pl={3}><Text mediumBlack14>{place.pay_period}</Text></Grid>
                                                                                <Grid item lg={2} md={2} sm={2} xs={2} className={classes.headerNames} pl={3}><Text mediumBlack14>{place.added_deducted_to}</Text></Grid>
                                                                                <Grid item lg={2} md={2} sm={2} xs={2} className={classes.headerNames} pl={3}><Text mediumBlack14>{place.pay_check_date}</Text></Grid>
                                                                                <Grid item lg={2} md={2} sm={2} xs={2} className={classes.headerNames} pl={3}><Text onClick={(e) => { if (place.payment_mode != 2) accordianHandler(e, place, 'balance') }} mediumBlack14 sx={{ textDecoration: place.payment_mode != 2 ? 'underline' : '', color: place.balance_impact < 0 ? `${cancelRed} !important` : '#15803D !important' }}>{LocalStorage.getCurrencySymbol()} {place.balance_impact}</Text></Grid>
                                                                                <Grid item lg={2} md={2} sm={2} xs={2} className={classes.headerNames} pl={3}><Text largeBlue sx={{ textDecoration: 'underline', color: place.current_balance < 0 ? `${cancelRed} !important` : '' }} onClick={(e) => accordianHandler(e, place, 'current')}>{LocalStorage.getCurrencySymbol()} {place.current_balance}</Text></Grid>
                                                                            </Grid>
                                                                        </AccordionSummary>
                                                                    </Accordion>
                                                                ))
                                                            }
                                                        </Box>
                                                        :
                                                        balanceData.length == 0 ?
                                                            <Box>
                                                                {NoDataFound(`No data found`)}
                                                            </Box>
                                                            : ''
                                                }

                                            </>
                                    }
                                </Box> :
                                <>
                                    {NoPermission()}
                                </>
                        }
                    </Box>

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={openBacKLoader}
                    // onClick={handleClose}
                    >
                        <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
                    </Backdrop>

                    <SwipeableDrawer
                        anchor={'right'}
                        open={drawer}
                        onClose={() => handleFilterDrawerClose()}
                        transitionDuration={300}
                        sx={{
                            ".MuiDrawer-paper ": {
                                borderRadius: '8px 0px 0px 8px !important',
                            },
                            "& .MuiBackdrop-root.MuiModal-backdrop": {
                                backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                            }
                        }
                        }
                    >
                        {
                            drawer === "filter" ?
                                <Box width={'660px'} height={'100vh'} >
                                    <Box height={'10vh'} borderBottom={'1px solid #EAECF0'} display={'flex'} alignItems={'center'} pl={4} >
                                        <Text headerBlack>Filters</Text>
                                    </Box>

                                    <Box display={'flex'} height={'7vh'} borderBottom={'1px solid #EAECF0'} alignItems={'center'} justifyContent={'space-between'} pr={'26px'} gap={1}>
                                        <Box display={'flex'} height={'60px'} alignItems={'center'} width={'90%'} pl={1} pr={'26px'} gap={1} sx={{ overflow: "auto", "&::-webkit-scrollbar": { display: "none !important" } }}>
                                            {
                                                filterState.pay_period_from !== "" && filterState.pay_period_to !== "" &&
                                                <>
                                                    <Chip
                                                        sx={{ border: "1px solid #E5E8E9" }}
                                                        key={1}
                                                        icon={<CircleIcon sx={{ color: "#9DE22D !important" }} className={classes.circularIcon} />}
                                                        label={<Text smallBlack>{filterState.pay_period_from} - {filterState.pay_period_to}</Text>}
                                                        variant="outlined"
                                                        onDelete={() => handleDeleteChip('', "pay_period")}
                                                        deleteIcon={<CloseIcon />} />
                                                </>
                                            }

                                            {
                                                filterState.balance_impact_from !== "" && filterState.balance_impact_to !== "" &&
                                                <>
                                                    <Chip
                                                        sx={{ border: "1px solid #E5E8E9" }}
                                                        key={1}
                                                        icon={<CircleIcon sx={{ color: "#EA5151 !important" }} className={classes.circularIcon} />}
                                                        label={<Text smallBlack>{filterState.balance_impact_from} - {filterState.balance_impact_to}</Text>}
                                                        variant="outlined"
                                                        onDelete={() => handleDeleteChip('', "balance_impact")}
                                                        deleteIcon={<CloseIcon />} />
                                                </>
                                            }
                                            {
                                                filterState.pay_check_date_from !== "" && filterState.pay_check_date_to !== "" &&
                                                <>
                                                    <Chip
                                                        sx={{ border: "1px solid #E5E8E9" }}
                                                        key={1}
                                                        icon={<CircleIcon sx={{ color: "#2DC890 !important" }} className={classes.circularIcon} />}
                                                        label={<Text smallBlack>{filterState.pay_check_date_from} - {filterState.pay_check_date_to}</Text>}
                                                        variant="outlined"
                                                        onDelete={() => handleDeleteChip('', "pay_check_date")}
                                                        deleteIcon={<CloseIcon />} />
                                                </>
                                            }
                                            {
                                                filterState.current_balance_from !== "" && filterState.current_balance_to !== "" &&
                                                <>
                                                    <Chip
                                                        sx={{ border: "1px solid #E5E8E9" }}
                                                        key={1}
                                                        icon={<CircleIcon sx={{ color: "#FF7A00 !important" }} className={classes.circularIcon} />}
                                                        label={<Text smallBlack>{filterState.current_balance_from} - {filterState.current_balance_to}</Text>}
                                                        variant="outlined"
                                                        onDelete={() => handleDeleteChip('', "current_balance")}
                                                        deleteIcon={<CloseIcon />} />
                                                </>
                                            }
                                            {
                                                paymentCycleList.map((item, key) => (
                                                    filterState.payment_mode.includes(item.id) &&
                                                    <Chip
                                                        sx={{ border: "1px solid #E5E8E9" }}
                                                        key={key}
                                                        icon={<CircleIcon sx={{ color: "#EA5151 !important" }} className={classes.circularIcon} />}
                                                        label={<Text smallBlack nowrap>{
                                                            item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                <BlackToolTip title={item.value} placement="right" arrow>
                                                                    {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                </BlackToolTip>
                                                                :
                                                                item.value
                                                        }</Text>}
                                                        variant="outlined"
                                                        onDelete={() => handleDeleteChip(item.id, "payment_mode")}
                                                        deleteIcon={<CloseIcon />}
                                                    />
                                                ))
                                            }
                                            {
                                                deductedFromDropdown.map((item, key) => (
                                                    filterState.deducted_or_added_to.includes(item.id) &&
                                                    <Chip key={key}
                                                        sx={{ border: "1px solid #E5E8E9" }}
                                                        icon={<CircleIcon sx={{ color: "#FF7A00 !important" }} className={classes.circularIcon} />}
                                                        label={<Text smallBlack>{item.value}</Text>}
                                                        variant="outlined"
                                                        onDelete={() => handleDeleteChip(item.id, "deducted_or_added_to")}
                                                        deleteIcon={<CloseIcon />}
                                                    />
                                                ))
                                            }

                                        </Box>
                                        {
                                            ((filterState.balance_impact_from !== "" && filterState.balance_impact_to !== "") || (filterState.pay_period_from !== "" && filterState.pay_period_to !== "") || (filterState.pay_check_date_from !== "" && filterState.pay_check_date_to !== "") || filterState.status.length > 0 || filterState.payment_mode.length > 0 || filterState.deducted_or_added_to.length > 0) ?
                                                <Button startIcon={<CloseIcon />} onClick={() => clearAllFilter()} clearAll >Clear All</Button>
                                                :
                                                ''
                                        }
                                    </Box>

                                    <Box display={'flex'} width={'100%'} border={'1px solid #EAECF0'} height={'73vh'} >
                                        <Box display={'flex'} flexDirection={'column'} height={'100%'} width={'43%'} borderRight={'1px solid #EAECF0'} pt={0} >
                                            <List component="nav" aria-label="secondary mailbox folder" sx={{ overflowY: 'scroll' }}>
                                                <ListItem className={selectedFilter == 1 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(1)} secondaryAction={filterState.payment_mode.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#EA5151" }}><Text mediumWhite400>{filterState.payment_mode.length}</Text></Box> : ""}>
                                                    <ListItemText primary={selectedFilter == 1 ? <Text ml={3} smallBlue1>Payment Cycle</Text> : <Text ml={3} mediumBlack>Payment Cycle</Text>} />
                                                </ListItem>
                                                <Divider className={classes.divider} />
                                                <ListItem className={selectedFilter == 2 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(2)} secondaryAction={(filterState.pay_period_from !== "" && filterState.pay_period_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#9DE22D" }}><Text mediumWhite400>{(filterState.pay_period_from !== "" && filterState.pay_period_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                    <ListItemText primary={selectedFilter == 2 ? <Text ml={3} smallBlue1>Pay Period</Text> : <Text ml={3} mediumBlack>Pay Period</Text>} />
                                                </ListItem>
                                                <Divider />
                                                <ListItem className={selectedFilter == 3 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(3)} secondaryAction={filterState.deducted_or_added_to.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#FF7A00" }}><Text mediumWhite400>{filterState.deducted_or_added_to.length}</Text></Box> : ""}>
                                                    <ListItemText primary={selectedFilter == 3 ? <Text ml={3} smallBlue1>Deducted / Added to</Text> : <Text ml={3} mediumBlack>Deducted / Added to</Text>} />
                                                </ListItem>
                                                <Divider />
                                                <ListItem className={selectedFilter == 4 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(4)} secondaryAction={(filterState.pay_check_date_from !== "" && filterState.pay_check_date_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#2DC890" }}><Text mediumWhite400>{(filterState.pay_check_date_from !== "" && filterState.pay_check_date_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                    <ListItemText primary={selectedFilter == 4 ? <Text ml={3} smallBlue1>Pay / Check Date</Text> : <Text ml={3} mediumBlack>Pay / Check Date</Text>} />
                                                </ListItem>
                                                <Divider />
                                                <ListItem className={selectedFilter == 5 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(5)} secondaryAction={(filterState.balance_impact_from !== "" && filterState.balance_impact_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#EA5151" }}><Text mediumWhite400>{(filterState.balance_impact_from !== "" && filterState.balance_impact_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                    <ListItemText primary={selectedFilter == 5 ? <Text ml={3} smallBlue1>Balance Impact</Text> : <Text ml={3} mediumBlack>Balance Impact</Text>} />
                                                </ListItem>
                                                <Divider />
                                                <ListItem className={selectedFilter == 6 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(6)} secondaryAction={(filterState.current_balance_from !== "" && filterState.current_balance_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#FF7A00" }}><Text mediumWhite400>{(filterState.current_balance_from !== "" && filterState.current_balance_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                    <ListItemText primary={selectedFilter == 6 ? <Text ml={3} smallBlue1>Current Balance</Text> : <Text ml={3} mediumBlack>Current Balance</Text>} />
                                                </ListItem>
                                                <Divider />
                                            </List>
                                        </Box>
                                        <Box display={'flex'} sx={{ overflow: "auto" }} flexDirection={'column'} height={'70vh'} width={'55%'} pl={'25px !important'} pt={2}>

                                            {
                                                selectedFilter == 1 ?
                                                    <>
                                                        {
                                                            paymentCycleList.length > 0 && paymentCycleList.map((item, index) => (
                                                                <Grid container alignItems={"center"} pb={2}>
                                                                    <FormControlLabel
                                                                        key={index}
                                                                        control={
                                                                            <Checkbox
                                                                                size="small"
                                                                                name={"payment_mode"}
                                                                                value={item.id}
                                                                                onChange={(e) => { handleChangeCheckBox(e) }}
                                                                                // onClick = {handleCheckClick()}
                                                                                icon={<CheckBorderIcon />}
                                                                                checkedIcon={<CheckedIcon />}
                                                                                checked={filterState.payment_mode.includes(item.id)} />}
                                                                        label={<Text mediumBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                            <BlackToolTip title={item.value} placement="right" arrow>
                                                                                {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                            </BlackToolTip>
                                                                            :
                                                                            item.value}</Text>}
                                                                    />
                                                                </Grid>
                                                            ))
                                                        }
                                                    </>
                                                    :
                                                    selectedFilter == 2 ?
                                                        <>
                                                            {
                                                                <Grid container alignItems={"center"}>
                                                                    <Grid item lg={5.5} md={5} sm={5}>
                                                                        <Date
                                                                            labelText={`From`}
                                                                            name='pay_period_from'
                                                                            value={filterState.pay_period_from}
                                                                            onChange={(value) => dateChange(value, 'pay_period_from')}
                                                                            height='56px'
                                                                        />
                                                                    </Grid>
                                                                    <Grid item lg={1} md={1} sm={1} textAlign={"center"}>
                                                                        &#8594;
                                                                    </Grid>
                                                                    <Grid item lg={5.5} md={5} sm={5}>
                                                                        <Date
                                                                            labelText={`To`}
                                                                            name='pay_period_to'
                                                                            value={filterState.pay_period_to}
                                                                            onChange={(value) => dateChange(value, 'pay_period_to')}
                                                                            height='56px'
                                                                            minDate={moment(filterState.pay_period_from).format(dateFormat())}
                                                                            disabled={filterState.pay_period_from == ""}
                                                                            error={datesError.pay_period_to}
                                                                        />
                                                                        <Box sx={{ height: "0px" }}>{datesError.pay_period_to ? <Text red>{datesError.pay_period_to ? datesError.pay_period_to : ''}</Text> : ''}</Box>
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                        </>
                                                        :
                                                        selectedFilter == 3 ?
                                                            <>
                                                                {
                                                                    deductedFromDropdown.length > 0 && deductedFromDropdown.map((item, index) => (
                                                                        <Grid container alignItems={"center"} pb={2}>
                                                                            <FormControlLabel
                                                                                key={index}
                                                                                control={
                                                                                    <Checkbox
                                                                                        size="small"
                                                                                        name={"deducted_or_added_to"}
                                                                                        value={item.id}
                                                                                        onChange={(e) => { handleChangeCheckBox(e) }}
                                                                                        // onClick = {handleCheckClick()}
                                                                                        icon={<CheckBorderIcon />}
                                                                                        checkedIcon={<CheckedIcon />}
                                                                                        checked={filterState.deducted_or_added_to.includes(item.id)} />}
                                                                                label={<Text mediumBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                                    <BlackToolTip title={item.value} placement="right" arrow>
                                                                                        {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                                    </BlackToolTip>
                                                                                    :
                                                                                    item.value}</Text>}
                                                                            />
                                                                        </Grid>
                                                                    ))
                                                                }
                                                            </>
                                                            :
                                                            selectedFilter == 4 ?
                                                                <>
                                                                    {
                                                                        <Grid container alignItems={"center"}>
                                                                            <Grid item lg={5.5} md={5} sm={5}>
                                                                                <Date
                                                                                    labelText={`From`}
                                                                                    name='pay_check_date_from'
                                                                                    value={filterState.pay_check_date_from}
                                                                                    onChange={(value) => dateChange(value, 'pay_check_date_from')}
                                                                                    height='56px'
                                                                                />
                                                                            </Grid>
                                                                            <Grid item lg={1} md={1} sm={1} textAlign={"center"}>
                                                                                &#8594;
                                                                            </Grid>
                                                                            <Grid item lg={5.5} md={5} sm={5}>
                                                                                <Date
                                                                                    labelText={`To`}
                                                                                    name='pay_check_date_to'
                                                                                    value={filterState.pay_check_date_to}
                                                                                    onChange={(value) => dateChange(value, 'pay_check_date_to')}
                                                                                    height='56px'
                                                                                    minDate={moment(filterState.pay_check_date_from).format(dateFormat())}
                                                                                    disabled={filterState.pay_check_date_from == ""}
                                                                                    error={datesError.pay_check_date_to}
                                                                                />
                                                                                <Box sx={{ height: "0px" }}>{datesError.pay_check_date_to ? <Text red>{datesError.pay_check_date_to ? datesError.pay_check_date_to : ''}</Text> : ''}</Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </>
                                                                :
                                                                selectedFilter == 5 ?
                                                                    <>
                                                                        {
                                                                            <Grid container alignItems={"center"} pb={2}>
                                                                                <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                                    <Box sx={{ width: 300, margin: "auto" }}>
                                                                                        <Text mediumBlack14 pb={1}>
                                                                                            Select Amount Range
                                                                                        </Text>
                                                                                        <Slider
                                                                                            value={[filterState.balance_impact_from, filterState.balance_impact_to]}
                                                                                            onChange={handleSliderChangeRaised}
                                                                                            valueLabelDisplay="auto"
                                                                                            min={0}
                                                                                            max={10000000}
                                                                                            sx={{ mb: 2 }}
                                                                                        />
                                                                                        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                                            <Input
                                                                                                formControlProps={{
                                                                                                    fullWidth: true
                                                                                                }}
                                                                                                inputProps={{
                                                                                                    name: 'balance_impact_from',
                                                                                                    value: filterState.balance_impact_from,
                                                                                                    inputProps: { maxLength: 50 },
                                                                                                }}
                                                                                                handleChange={(e) => handleInputChange(0, e.target.value)}
                                                                                                clientInput
                                                                                                labelText={'$ Min'}
                                                                                                size="small"
                                                                                                sx={{ width: "35%" }}
                                                                                            />
                                                                                            <Input
                                                                                                formControlProps={{
                                                                                                    fullWidth: true
                                                                                                }}
                                                                                                inputProps={{
                                                                                                    name: 'balance_impact_to',
                                                                                                    value: filterState.balance_impact_to,
                                                                                                }}
                                                                                                handleChange={(e) => handleInputChange(1, e.target.value)}
                                                                                                clientInput
                                                                                                labelText={'$ Max'}
                                                                                                size="small"
                                                                                                sx={{ width: "35%" }}
                                                                                            />
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Grid>
                                                                            </Grid>
                                                                        }
                                                                    </>
                                                                    :
                                                                    selectedFilter == 6 ?
                                                                        <>
                                                                            {
                                                                                <Grid container alignItems={"center"} pb={2}>
                                                                                    <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                                        <Box sx={{ width: 300, margin: "auto" }}>
                                                                                            <Text mediumBlack14 pb={1}>
                                                                                                Select Amount Range
                                                                                            </Text>
                                                                                            <Slider
                                                                                                value={[filterState.current_balance_from, filterState.current_balance_to]}
                                                                                                onChange={handleSliderChangeCurrent}
                                                                                                valueLabelDisplay="auto"
                                                                                                min={0}
                                                                                                max={10000000}
                                                                                                sx={{ mb: 2 }}
                                                                                            />
                                                                                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                                                                                <Input
                                                                                                    formControlProps={{
                                                                                                        fullWidth: true
                                                                                                    }}
                                                                                                    inputProps={{
                                                                                                        name: 'current_balance_from',
                                                                                                        value: filterState.current_balance_from,
                                                                                                        inputProps: { maxLength: 50 },
                                                                                                    }}
                                                                                                    handleChange={(e) => handleInputChange(0, e.target.value)}
                                                                                                    clientInput
                                                                                                    labelText={'$ Min'}
                                                                                                    size="small"
                                                                                                    sx={{ width: "35%" }}
                                                                                                />
                                                                                                <Input
                                                                                                    formControlProps={{
                                                                                                        fullWidth: true
                                                                                                    }}
                                                                                                    inputProps={{
                                                                                                        name: 'current_balance_to',
                                                                                                        value: filterState.current_balance_to,
                                                                                                    }}
                                                                                                    handleChange={(e) => handleInputChange(1, e.target.value)}
                                                                                                    clientInput
                                                                                                    labelText={'$ Max'}
                                                                                                    size="small"
                                                                                                    sx={{ width: "35%" }}
                                                                                                />
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <Box sx={{ height: "60vh", width: "100%", display: "flex", alignItems: "center" }}>
                                                                            <Grid container>
                                                                                <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                                    <img src={nofilterplacement} alt="nofilterplacement" style={{ paddingTop: "15px" }} />
                                                                                </Grid>
                                                                                <Grid item lg={12} md={12} sm={12} textAlign={"center"} >
                                                                                    <Text veryLargeLabel>Gear Up!</Text>
                                                                                </Grid>
                                                                                <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                                    <Text offBoardBodyFont>Select filters to scale our data peaks</Text>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                // nofilterplacement
                                            }
                                        </Box>
                                    </Box>

                                    <Box display={'flex'} alignItems={'center'} justifyContent={'end'} gap={1} height={'73px'} pr={'26px'}>
                                        <Button cancelSmall onClick={() => cancelFilter()}>Cancel</Button>
                                        <Button saveSmall onClick={() => handleApplyFilters()} >Apply Filters</Button>
                                    </Box>
                                </Box >
                                : null
                        }

                    </SwipeableDrawer>
                </Box>
                <ReusablePopup white iconHide statusWidth openPopup={currentOpen} setOpenPopup={setCurrentOpen} onClose={() => { setCurrentOpen(false) }} showTitle={true}>
                    <CurrentBalancePopup type={type} currentBalanceRow={currentBalanceRow} />
                </ReusablePopup>
            </Grid>
        </Grid>
    )
}