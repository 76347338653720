import { useState } from 'react'; // eslint-disable-next-line
import { addErrorMsg, addSuccessMsg } from '../../../utils/utils';
import { isValid, onCharactersAndNumbersWithSpaceChange, validate_charWithSpace, validate_emptyField } from '../../../components/Validation';
import Text from '../../../components/customText/Text';
import LocalStorage from "../../../utils/LocalStorage";
import Input from '../../../components/input/Input';
import LoadingButton from '../../../components/customButton/LoadingButton';
import PayrollApi from '../../../apis/admin/payroll/ExpenseManagement';
import { Grid, Box } from '@mui/material';
import Button from '../../../components/customButton/Button';

function AddReimbursementType(props) {

    const [value, setValue] = useState({
        request_id: LocalStorage.uid(),
        id: "",
        name: "",
        description: "",
        expense_type: 'reimbursement',
    });
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState({});

    const changes = (e) => {
        setValue(
            {
                ...value,
                [e.target.name]: e.target.value,
            },
            handleValidate(e)
        );
    };
    const handleClose = () => {
        props.closePopup(false);
        props.payTypeApi();
    }

    const handleSubmit = () => {
        let errors = finalValidations();
        if (isValid(errors)) {
            createReimbursement();
        } else {
            let s1 = { error };
            s1 = errors;
            setError(s1);
        }
    }

    const createReimbursement = () => {
        setLoading(true);
        PayrollApi.storeExpenseType(value, LocalStorage.getAccessToken()).then((response) => {
            if (response.data.statusCode == 1003) {
                handleClose();
                setLoading(false);
                addSuccessMsg(response.data.message)
            } else {
                setLoading(false);
                addErrorMsg(response.data.message)
            }
        });
    };

    const handleValidate = (e) => {
        let input = e.target;
        let s1 = { ...error };
        switch (input.name || input.tagName) {
            case "name":
                error.name = validate_charWithSpace(input.value);
                break;
            case "description":
                error.description = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setError(s1);
    };

    const finalValidations = () => {
        let { name } = value;
        let errors = {};
        errors.name = validate_charWithSpace(name);
        return errors;
    };


    return (
        <Grid container spacing={3} p={'0px 10px'}>
            <Grid item lg={12}>
                <Text largeBlue>Reimbursement Type</Text>
            </Grid>
            <Grid item lg={12}>
                <Input
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        name: 'name',
                        value: value.name,
                        inputProps: { maxLength: 50 }
                    }}
                    clientInput
                    handleChange={changes}
                    onKeyPress={onCharactersAndNumbersWithSpaceChange}
                    error={error.name}
                    labelText='Name'
                />
                <Text errorText> {error.name ? error.name : ""}</Text>
            </Grid>
            <Grid item lg={12}>
                <Input
                    formControlProps={{
                        fullWidth: true
                    }}
                    multiline={true}
                    rows={3}
                    inputProps={{
                        name: 'description',
                        value: value.description,
                        inputProps: { maxLength: 255 }
                    }}
                    descriptionFormControl1
                    descriptionInput
                    labelText={'Description (Optional)'}
                    handleChange={changes}
                />
            </Grid>
            <Grid item lg={12}>
                <Box display='flex' flexDirection='row' gap={2}>
                    <Button blackCancel100 onClick={handleClose}>Cancel</Button>
                    <LoadingButton loading={loading} blueButton onClick={handleSubmit}>Save</LoadingButton>
                </Box>
            </Grid>
        </Grid>
    )
}

export default AddReimbursementType
