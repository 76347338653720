import APIURL from "../../config/development";
import LocalStorage from "../../utils/LocalStorage";
import BaseApi from "../BaseApi";

class DasboardAPI {
    getEmployeesList() {
        return BaseApi.getWithParams(APIURL.API_URL + `dashboard/get-employees-data?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }
    totalRecievables() {
        return BaseApi.getWithParams(APIURL.API_URL + `dashboard/receivables-payables?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }
    getCompanies() {
        return BaseApi.getWithParams(APIURL.API_URL + `dashboard/get-top-companies?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    getPayroll(id) {
        return BaseApi.getWithParams(APIURL.API_URL + `dashboard/payroll?request_id=${LocalStorage.uid()}&payroll_setting_id=${id}`, LocalStorage.getAccessToken())
    }

    payrollConfigDropdown() {
        return BaseApi.getWithParams(APIURL.org_URL + `payroll-config-settings/dropdown?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    remaindersListing(slug, data) {
        const { perPage, currentPage } = data
        return BaseApi.getWithParams(APIURL.API_URL + `reminders/listing/${slug}?page=${currentPage}&limit=${perPage}&request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    timesheetRemaindersListing(slug, data, payCycle, payPeriod, fromDate, toDate) {
        const { perPage, currentPage } = data
        return BaseApi.getWithParams(APIURL.API_URL + `reminders/listing/${slug}?page=${currentPage}&pay_cycle=${payCycle}&limit=${perPage}&request_id=${LocalStorage.uid()}&pay_period=${payPeriod}&from_date=${fromDate}&to_date=${toDate}`, LocalStorage.getAccessToken())
    }

    storeReminder(data) {
        return BaseApi.postWithToken(APIURL.API_URL + `self-reminder-config/store`, data, LocalStorage.getAccessToken())
    }

    cashflow(data) {
        const { viewType, fromDate, toDate } = data;
        return BaseApi.getWithParams(APIURL.API_URL + `dashboard/cashflow?request_id=${LocalStorage.uid()}&view_type=${viewType}&from_date=${fromDate}&to_date=${toDate}`, LocalStorage.getAccessToken())
    }

    selfDocuments(date) {
        return BaseApi.getWithParams(APIURL.API_URL + `self-reminder-config/listing?request_id=${LocalStorage.uid()}&date=${date}`, LocalStorage.getAccessToken())
    }

    employerMargin(data) {
        const { fromDate, toDate } = data;
        return BaseApi.getWithParams(APIURL.API_URL + `dashboard/employee-margin?request_id=${LocalStorage.uid()}&from_date=${fromDate}&to_date=${toDate}`, LocalStorage.getAccessToken())
    }

    storeChart(data) {
        return BaseApi.postWithToken(APIURL.API_URL + `chat-bot`, data, LocalStorage.getAccessToken())
    }

    payPeriodList(id) {
        return BaseApi.getWithParams(APIURL.API_URL + `reminder-config/payroll-config-dates?request_id=${LocalStorage.uid()}&payroll_cycle_id=${id}`, LocalStorage.getAccessToken())
    }

    dashboardGetApi(year) {
        return BaseApi.getWithParams(APIURL.org_URL + `dashboard/analytics?request_id=${LocalStorage.uid()}&year=${year}`, LocalStorage.getAccessToken());
    }

    dashboardPlacementGetApi(year) {
        return BaseApi.getWithParams(APIURL.org_URL + `dashboard/placement-analytics?request_id=${LocalStorage.uid()}&year=${year}`, LocalStorage.getAccessToken());
    }

    pendingRequestListing(limit, page) {
        return BaseApi.getWithParams(APIURL.employee_URL + `pending-actions?request_id=${LocalStorage.uid()}&limit=${limit}&page=${page}`, LocalStorage.getAccessToken());
    }

    pendingRequestListingConsultant(limit) {
        return BaseApi.getWithParams(APIURL.employee_URL + `consultant/employee/pending-actions?request_id=${LocalStorage.uid()}&limit=${limit}&employee_id=${LocalStorage.getUserData().login_id}`, LocalStorage.getAccessToken());
    }

    pendingemployeeListing(data) {
        return BaseApi.postWithToken(APIURL.employee_URL + `employee/pending-requests`,data, LocalStorage.getAccessToken());
    }

    pendingRequestIndex(id) {
        return BaseApi.getWithParams(APIURL.employee_URL + `employee/pending-requests?request_id=${LocalStorage.uid()}&employee_id=${id}`, LocalStorage.getAccessToken());
    }

    pendingEmployeeIndex(id) {
        return BaseApi.getWithParams(APIURL.employee_URL + `employee/pending-requests/${id}?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    updateApprove(data) {
        return BaseApi.putWithToken(APIURL.employee_URL + `employee/pending-requests/update-status`, data, LocalStorage.getAccessToken());
    }

    planCheck(data) {
        return BaseApi.postWithToken(APIURL.AdminURL + `plan-check`, data, LocalStorage.getAccessToken())
    }

    timesheetGraphListingConsultant(year) {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `timesheets/consultant/analytics?request_id=${LocalStorage.uid()}&year=${year}&employee_id=${LocalStorage.getUserData().login_id}`, LocalStorage.getAccessToken());
    }
    bannerListingConsultant() {
        return BaseApi.getWithParams(APIURL.org_URL + `announcement/listing?request_id=${LocalStorage.uid()}&limit=10&page=1&platform=Website`, LocalStorage.getAccessToken());
    }

    notificationBellList(from_date, to_date, limit, page,listing=false) {
        return BaseApi.getWithParams(APIURL.org_URL + `notification/listing?request_id=${LocalStorage.uid()}&from_date=${from_date}&to_date=${to_date}&limit=${limit}&page=${page}&list=${listing}`, LocalStorage.getAccessToken())
    }
    notificationUpdate(id, data) {
        return BaseApi.putWithToken(APIURL.org_URL + `notification/update-status/${id}`, data, LocalStorage.getAccessToken())
    }
    zohoIntegration(data){
        return BaseApi.postWithToken(APIURL.org_URL+`zoho-sync/placement`,data,LocalStorage.getAccessToken())
    }
}
// eslint-disable-next-line
export default new DasboardAPI();