import React, { useState } from 'react';
import { Box, Breadcrumbs, Grid, Divider, ListItemButton, Menu, MenuItem, CircularProgress, Skeleton, SwipeableDrawer, Stack, Hidden } from '@mui/material';
import CompanyDetails from './CompanyDetails';
import ContactDetails from './ContactDetails';
import { useLocation, useNavigate } from 'react-router-dom';
import VendorStyles from './VendorStyles';
import { useEffect } from 'react';
import CommonApi from '../../../../apis/CommonApi';
import LocalStorage from '../../../../utils/LocalStorage';
import ClientsApi from '../../../../apis/admin/clients/ClientsApi';
import { BlackToolTip, NoPermission, addErrorMsg, addSuccessMsg, capitalizeAndAddSpace } from '../../../../utils/utils';
import Text from '../../../../components/customText/Text';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import deactivateImg from '../../../../assets/svg/clients/clientDeactivate.svg';
import menu from '../../../../assets/svg/clients/client-menu-withoutbg.svg';
import CustomCircularProgress from '../../../../components/progressbar/CircularProgress'
import Button from '../../../../components/customButton/Button';
import ActivityLog from '../../employees/userprofile/ActivityLog';
import FileSaver from 'file-saver';
import eVerifyPending from '../../../../assets/employee/pendingEverify.svg';
import offBoardSave from "../../../../assets/svg/offBoardSave.svg"
import Sure from '../../../../assets/svg/placementPop.svg'
import deactivatedPlacement from '../../../../assets/svg/clients/deactivated-placement.svg'
import LoadingButton from '../../../../components/customButton/LoadingButton';

const dataArr = ["Vendor Details", "Contact Details"];

export default function VendorUserProfile(props) {
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = VendorStyles();
    const location = useLocation();
    const id = LocalStorage.getRedirectedModule() == 'vendor' ? LocalStorage.getNotificationId() : location && location.state && location.state.data.id
    const name = location && location.state && location.state.data.name
    const complete = location && location.state && location.state.complete
    const navigate = useNavigate();
    const [action, setAction] = useState('view');
    const [actionContact, setActionContact] = useState('view');
    const [current, setCurrent] = useState("Vendor Details");
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openPopup, setOpenpopup] = useState(false);
    const [stepItem, setStepItem] = useState('Vendor Details');
    const open = Boolean(anchorEl);
    const [statusLoading, setStatusLoading] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false);
    const [eventData, setEventData] = useState('');
    const [enbaleEdit, setEnableEdit] = useState('');
    const [employeeMapped, setEmployeeMapped] = useState(false);
    const [state, setState] = useState(
        {
            id: '',
            name: "",
            reference_id: "",
            logo_path_id: '',
            logo_name: '',
            logo_url: '',
            address: [
                {
                    address_line_one: "",
                    address_line_two: "",
                    city: "",
                    state_id: "",
                    country_id: "",
                    country: null,
                    zip_code: ""
                }
            ],net_pay_terms_id:'',net_pay_term_days:''
        }
    )

    const [viewData, setViewData] = useState(
        {
            name: "",
            reference_id: "",
            logo_path_id: '',
            logo_name: '',
            logo_url: '',
            address: [
                {
                    address_line_one: "",
                    address_line_two: "",
                    city: "",
                    state_id: "",
                    country_id: "",
                    country: null,
                    zip_code: ""
                }
            ],
            isMappedToEmployees: false,
            net_pay_terms_id:'',net_pay_term_days:''
        }
    );
    const [loader, setLoader] = useState(false)
    const [activityAnchorEl, setActivityAnchorEl] = useState(null);
    const activityOpen = Boolean(activityAnchorEl)
    const [activityTotal, setActivityTotal] = useState();
    const [activityData, setActivityData] = useState([]);
    const [activityFilter, setActivityFilter] = useState({
        limit: 100,
        page: 1,
        search: "",
        request_id: LocalStorage.uid(),
        employee_id: ''
    })
    const handleActivityClick = (event) => {
        setActivityAnchorEl(event.currentTarget);
    };

    const downloadActivity = () => {
        let data = {
            request_id: LocalStorage.uid(),
            id: id
        }
        ClientsApi.exportactivity('vendor', data).then((response) => {
            if (response.data.statusCode == 1003) {
                let url = response.data.data[0].file_path
                let fileName = url.split('/exports/')
                FileSaver.saveAs(response.data.data[0].file_path, fileName[1]);
            }
            else {
                addErrorMsg(response.data.message)
            }
        });
    }

    const handleActivityClose = () => {
        setActivityAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const completeProfile = () => {
        if (viewData.profile_percentage == 50) {
            setCurrent("Contact Details");
            setActionContact('view');
        }
    }

    const updateClientStatus = () => {
        let data = {
            id: id,
            request_id: LocalStorage.uid(),
            status: viewData.status == 'Active' ? 'In Active' : 'Active'
        };
        setLoader(true)
        if (viewData.isMappedToEmployees && viewData.status == 'Active') {
            setEmployeeMapped(true);
            setDeletePopup(false);
            setAnchorEl(null);
            getActivity();
            getCompanyDetails();
            setCurrent('Vendor Details');
            setLoader(false)
        } else {
            setStatusLoading(true)
            ClientsApi.updateStatus("vendor", data).then((response) => {
                setTimeout(() => {
                    setStatusLoading(false)
                    if (response.data.statusCode == 1003) {
                        setDeletePopup(false);
                        setAnchorEl(null);
                        addSuccessMsg(response.data.message);
                        getActivity();
                        getCompanyDetails();
                        setCurrent('Vendor Details');
                        setLoader(false)
                    } else {
                        addErrorMsg(response.data.message);
                        setLoader(false)
                    }
                }, 400)
            });
        }
    }

    useEffect(() => {
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_view" && item.is_allowed == true))) {
            getActivity(activityFilter);
            getCompanyDetails();
        }
        if (complete == 'contact') {
            setCurrent('Contact Details');
        } else {
            setCurrent('Vendor Details');
            setAction('view')
        } // eslint-disable-next-line 
    }, [])

    useEffect(() => {
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_view" && item.is_allowed == true))) {
            getActivity(activityFilter);
        }
        // eslint-disable-next-line
    }, [activityFilter])

    const getActivity = (args) => {
        let data = {
            from: '',
            to: '',
            search: activityFilter.search,
            request_id: LocalStorage.uid(),
            id: id
        }
        ClientsApi.downloadActivity(data, 'vendor').then((response) => {
            if (response.data.statusCode == 1003) {
                setActivityData(response.data.data);
                setActivityTotal(response.data.pagination.total)
            }
        });
    }

    const uploadProfile = (e) => {
        if (e.target.files[0].type.split('/').some(r => ['png', 'jpg', 'jpeg'].includes(r))) {
            if (((e.target.files[0].size / (1024 * 1024)).toFixed(2)) < '25') {
                setStatusLoading(true)
                const formData = new FormData();
                formData.append("files", e.target.files[0]);
                formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
                CommonApi.documentUpload("company-logo", formData)
                    .then((response) => {
                        if (response.data.statusCode == 1003) {
                            let data = {
                                request_id: LocalStorage.uid(),
                                logo_path_id: response.data.data.id,
                                id: id
                            };
                            ClientsApi.uploadClientLogo("vendor", data).then((response) => {
                                if (response.data.statusCode == 1003) {
                                    state['logo_url'] = (response.data.data.logo_url);
                                    setState({ ...state });
                                    viewData['logo_url'] = (response.data.data.logo_url);
                                    setViewData({ ...viewData });
                                    addSuccessMsg(response.data.message);
                                    setStatusLoading(false);
                                    getActivity();
                                    getCompanyDetails();
                                } else {
                                    setStatusLoading(false)
                                    addErrorMsg(response.data.message);
                                }
                            });
                        } else {
                            setStatusLoading(false)
                            addErrorMsg(response.data.message);
                        }
                    });
            }
            else {
                addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
            }
        } else {
            setLoading(false);
            addErrorMsg("Please upload files in PNG or JPG format only.");
        }
    }

    const openDeletPop = () => {
        if (props.formEdit && (action == 'update' || actionContact == 'update')) {
            setOpenpopup(true);
            setEnableEdit('Status');
        } else {
            setDeletePopup(true);
        }
    }

    const discontinueHandler = () => {
        props.setformEdit(false);
        setCurrent(stepItem);
        setOpenpopup(false);
        getCompanyDetails();
        setActionContact('view'); setAction('view');
        setAnchorEl(null);
        if (enbaleEdit == 'clientNavigate') {
            navigate('/clients', { state: { page: 'vendors' } })
        }
        if (enbaleEdit == 'upload') {
            uploadProfile(eventData);
        }
        if (enbaleEdit == 'Status') {
            updateClientStatus();
        }
    }

    const getCompanyDetails = () => {
        setLoading(true);
        ClientsApi.companyIndexApi(id, "vendor").then((response) => {
            setTimeout(() => {
                setLoading(false);
                if (response.data.statusCode == 1003) {
                    setState({ ...response.data.data });
                    setViewData({ ...response.data.data })
                } else {
                    addErrorMsg(response.data.message);
                }
            }, 400)
        });
    }

    const breadCrumbsHandler = () => {
        if (props.formEdit && (action == 'update' || actionContact == 'update')) {
            setOpenpopup(true);
            setEnableEdit('clientNavigate');
        } else {
            navigate('/clients', { state: { page: 'vendors' } })
        }
    }

    return (
        <Box className={classes.mainContainer} px={5} py={0}>
            <Box mx={2}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Text boldGrey14 onClick={breadCrumbsHandler} className={classes.cursor}>All Vendors</Text>
                    <Text largeBldBlack>Vendor Profile</Text>
                </Breadcrumbs>
            </Box>
            <Hidden lgDown>
                {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_view" && item.is_allowed == true))) ?
                    <Box my={2} mx={2}>
                        <Grid container spacing={0} columnSpacing={3}>
                            <Grid item container lg={3.5} md={3} sm={4} xs={12} pr={2}>
                                <Box className={classes.cardBg}>
                                    {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_edit" && item.is_allowed == true))) &&
                                        <Box width={"100%"} display={'flex'} justifyContent={'end'} px={2} pt={2}>
                                            {
                                                loading ?
                                                    <Grid item container lg={12} justifyContent='end'>
                                                        <Skeleton animation='linear' className={classes.skeleton} />
                                                    </Grid>
                                                    :
                                                    <img src={menu} alt='menu' className={classes.cursor} onClick={handleClickMenu} />
                                            }
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                                sx={{
                                                    '& .MuiPaper-root, .MuiMenu-list': {
                                                        padding: '0px ',
                                                    },
                                                }}
                                            >
                                                {
                                                    (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_edit" && item.is_allowed == true))) ?
                                                        <MenuItem onClick={openDeletPop} className={classes.inactive}>
                                                            <Text smallBlack>{viewData.status == 'In Active' ? 'Activate' : 'Deactivate'}</Text>
                                                            {statusLoading ? <CircularProgress color="secondary" size={"12px"} sx={{ margin: "0px 6px" }} /> : ""}
                                                        </MenuItem> :
                                                        <MenuItem className={classes.inactive}><Text greyLabel sx={{ cursor: 'default' }}>{viewData.status == 'In Active' ? 'Activate' : 'Deactivate'}</Text></MenuItem>
                                                }
                                            </Menu>
                                        </Box>}
                                    <Box textAlign={'center'}>
                                        {
                                            loading ?
                                                <Grid item container lg={12} justifyContent='center'>
                                                    <Skeleton animation='linear' className={classes.profileskeleton} />
                                                </Grid> :
                                                <CustomCircularProgress
                                                    percentage={viewData.profile_percentage}
                                                    color={viewData.profile_percentage >= 76 ? 'green' : viewData.profile_percentage <= 75 && viewData.profile_percentage >= 51 ? 'yellow' : viewData.profile_percentage <= 50 ? "#FFBF00" : ''}
                                                    src={viewData.logo_url}
                                                    name={viewData.logo_url == undefined || viewData.logo_url == null || viewData.logo_url == '' ? name && name[0] : ''}
                                                    imgWidth='80px !important'
                                                    imgHeight='80px !important'
                                                    avatarWidth='80px !important'
                                                    avatarHeight='80px !important'
                                                    value={viewData.profile_percentage}
                                                    size={88}
                                                    edit={(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_edit" && item.is_allowed == true))) && viewData.status == 'Active'}
                                                    handleEdit={(e) => props.formEdit && (action == 'update' || actionContact == 'update') ? (setOpenpopup(true), setEventData(e), setEnableEdit('upload')) : uploadProfile(e)}
                                                    tooltip
                                                    tooltipContent={
                                                        <Box p={'3px 0px'} width='100%'>
                                                            <Text mediumBoldWhite400>{`Profile Completion - ${viewData.profile_percentage}%`}</Text>
                                                            {
                                                                viewData.profile_percentage == 100 ? '' : <Button addButtonTooltip sx={{ marginTop: '10px' }} onClick={completeProfile}>Complete Profile</Button>
                                                            }
                                                        </Box>
                                                    }
                                                />
                                        }
                                    </Box>
                                    <Grid item lg={12} px={3} textAlign='center' py={1}>
                                        {
                                            loading ?
                                                <Grid item container lg={12} justifyContent='center'>
                                                    <Skeleton animation='linear' className={classes.textSkeleton} />
                                                </Grid> :
                                                <Text largeHeaderText sx={{ textTransform: 'capitalize !important' }}>
                                                    {
                                                        viewData.name && viewData.name.length > 16 ?
                                                            <BlackToolTip title={capitalizeAndAddSpace(viewData.name)} placement="top" arrow>
                                                                {capitalizeAndAddSpace(viewData.name.slice(0, 16)) + (capitalizeAndAddSpace(viewData.name.length > 16 ? "..." : ""))}
                                                            </BlackToolTip>
                                                            : capitalizeAndAddSpace(viewData.name)
                                                    }
                                                </Text>
                                        }
                                    </Grid>
                                    <Grid item lg={12} px={3} textAlign='center' pb={2}>
                                        {
                                            loading ?
                                                <Grid item container lg={12} justifyContent='center'>
                                                    <Skeleton animation='linear' className={classes.textSkeleton} />
                                                </Grid> :
                                                <Text largeGrey14 noWrap>{viewData.reference_id}</Text>
                                        }
                                    </Grid>
                                    <Grid item lg={12} textAlign='center' mb={4}>
                                        {
                                            loading ?
                                                <Grid item container lg={12} justifyContent='center'>
                                                    <Skeleton animation='linear' className={classes.textSkeleton} />
                                                </Grid> :
                                                <Text mediumBoldBlue noWrap onClick={handleActivityClick} sx={{ cursor: 'pointer' }}>Activity Log</Text>
                                        }
                                    </Grid>
                                    <Box px={3}>
                                        <Divider />
                                    </Box>
                                    <Box p={3} className={classes.clientList}>
                                        {
                                            loading ?
                                                <>
                                                    {
                                                        [1, 2, 3, 4].map(() => (
                                                            <Grid item container lg={12} justifyContent='center'>
                                                                <Skeleton animation='linear' className={classes.textSkeleton} />
                                                            </Grid>
                                                        ))
                                                    }
                                                </> :
                                                <>
                                                    {
                                                        dataArr.map((item, key) => (
                                                            <ListItemButton
                                                                key={key}
                                                                className={`${classes.listItems} ${current === item ? classes.listItemsActive : null}`}
                                                                onClick={() => {
                                                                    // setCurrent(item);
                                                                    // if (current == item) { }
                                                                    // else {
                                                                    //     setActionContact('view'); setAction('view')
                                                                    // }
                                                                    if (current == item) { }
                                                                    else if (props.formEdit) {
                                                                        setOpenpopup(true); setStepItem(item)
                                                                    }
                                                                    else {
                                                                        setActionContact('view'); setAction('view'); setCurrent(item); setStepItem(item)
                                                                    }
                                                                }}
                                                            >
                                                                <Grid container>
                                                                    <Grid item xs={10}>{item}</Grid>
                                                                    {item === 'Contact Details' && viewData.profile_percentage == 50 &&
                                                                        <Grid item container justifyContent={'flex-end'} xs={2}>
                                                                            <BlackToolTip arrow placement='right' title={'Pending'}>
                                                                                <img src={eVerifyPending} alt='pending' />
                                                                            </BlackToolTip>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            </ListItemButton>
                                                        ))}
                                                </>
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={8.5} md={9} sm={8} xs={12}>
                                <Box className={classes.cardContent}>
                                    {
                                        current === "Vendor Details" ? <CompanyDetails formEdit={props.formEdit} setformEdit={props.setformEdit} id={id} action={action} setAction={setAction} state={state} setState={setState} getCompanyDetails={getCompanyDetails} getActivity={getActivity} loading={loading} setLoading={setLoading} /> : null
                                    }
                                    {/* {
                                    current === "Placements" ? <VendorPlacements id={id} /> : null
                                } */}
                                    {
                                        current === "Contact Details" ? <ContactDetails formEdit={props.formEdit} setformEdit={props.setformEdit} id={id} actionContact={actionContact} setActionContact={setActionContact} status={state.status} getActivity={getActivity} loading={loading} setLoading={setLoading} getCompanyDetails={getCompanyDetails} /> : null
                                    }
                                </Box>
                            </Grid>
                            <ReusablePopup iconHide white openPopup={deletePopup} setOpenPopup={setDeletePopup} statusWidth>
                                <Box textAlign='center'>
                                    <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                                        <img src={viewData.status == 'In Active' ? Sure : deactivateImg} alt="warning" />
                                    </Box>
                                    <Box sx={{ margin: '20px 0px 10px 0px' }}>
                                        <Text popupHead1>
                                            Are You Sure?
                                        </Text>
                                        <Text largeLabel sx={{ fontWeight: '400 !important' }} my={3}>
                                            Do you want to {viewData.status == 'In Active' ? 'Activate' : 'Deactivate'} the Vendor <span className={classes.blueText}>{capitalizeAndAddSpace(viewData.name)}</span>
                                        </Text>
                                    </Box>
                                    <Grid container lg={12} xs={12} justifyContent={'center'} alignContent={'center'} mt={1} spacing={2}>
                                        <Grid item lg={5} xs={6} textAlign='right'>
                                            <Button onClick={() => { setDeletePopup(false); setAnchorEl(null); }} redBorder>Cancel</Button>
                                        </Grid>
                                        <Grid item lg={5} xs={6} textAlign='left'>
                                        <LoadingButton onClick={updateClientStatus} loading={loader} blueButton>Yes</LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </ReusablePopup>
                            <SwipeableDrawer
                                anchor={'right'}
                                open={activityOpen}
                                onClose={handleActivityClose}
                                // onOpen={toggleDrawer(true, state.view)}
                                transitionDuration={300}
                                sx={{
                                    ".MuiDrawer-paper ": {
                                        borderRadius: '8px 0px 0px 8px !important',
                                    },
                                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                                    }
                                }
                                }
                            >
                                <ActivityLog
                                    activityTotal={activityTotal}
                                    activityData={activityData}
                                    activityFilter={activityFilter}
                                    setActivityFilter={setActivityFilter}
                                    downloadActivity={downloadActivity}
                                    employee={id} setLoading={setLoading} loading={loading}
                                    placeholder='Search by Vendor details'
                                />
                            </SwipeableDrawer>
                            <ReusablePopup openPopup={openPopup} setopenPopup={setOpenpopup} white iconHide fixedWidth>
                                <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                                    <img src={offBoardSave} alt='noactive' />
                                </Grid>
                                <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                                    <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Do you want to discontinue?</Text>
                                </Grid>
                                <Grid container justifyContent={'center'} alignContent={'center'}>
                                    <Text offBoardBodyFont>Your progress will not be saved</Text>
                                </Grid>
                                <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                                    <Stack direction={"row"} spacing={3}>
                                        <Button smallBlackOutline onClick={() => { setOpenpopup(false) }}>
                                            Cancel
                                        </Button>
                                        <Button smallBlue redBackground onClick={discontinueHandler} >
                                            Yes
                                        </Button>
                                    </Stack>
                                </Grid>
                            </ReusablePopup>
                        </Grid>
                    </Box>
                    :
                    <>
                        {NoPermission()}
                    </>
                }
            </Hidden>
            <Hidden lgUp>
                <Grid container md={12} xs={12} justifyContent='center' mt={2}>
                    <Grid item container md={11} xs={12} sx={{
                        border: '1px solid #EAECF0', borderRadius: '12px', padding: '15px'
                    }}>
                        <Grid item container md={9} xs={8} columnSpacing={2}>
                            <Grid item md={2} xs={3}>
                                <CustomCircularProgress
                                    percentage={viewData.profile_percentage}
                                    color={viewData.profile_percentage >= 76 ? 'green' : viewData.profile_percentage <= 75 && viewData.profile_percentage >= 51 ? 'yellow' : viewData.profile_percentage <= 50 ? "#FFBF00" : ''}
                                    src={viewData.logo_url}
                                    name={viewData.name[0]}
                                    imgWidth='80px !important'
                                    imgHeight='80px !important'
                                    avatarWidth='80px !important'
                                    avatarHeight='80px !important'
                                    value={viewData.profile_percentage}
                                    size={88}
                                    edit={(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_edit" && item.is_allowed == true))) && viewData.status == 'Active'}
                                    handleEdit={(e) => props.formEdit && (action == 'update' || actionContact == 'update') ? (setOpenpopup(true), setEventData(e), setEnableEdit('upload')) : uploadProfile(e)}
                                    tooltip
                                    tooltipContent={
                                        <Box p={'3px 0px'} width='100%'>
                                            <Text mediumBoldWhite400>{`Profile Completion - ${viewData.profile_percentage}%`}</Text>
                                            {
                                                viewData.profile_percentage == 100 ? '' : <Button addButtonTooltip sx={{ marginTop: '10px' }} onClick={completeProfile}>Complete Profile</Button>
                                            }
                                        </Box>
                                    }

                                />
                            </Grid>
                            <Grid item container md={6} xs={6} alignItems='center' ml={1}>
                                <Box alignItems='center'>
                                    <Box display='flex' flexDirection='row' gap={1} justifyContent='center' alignItems='center'>
                                        <Text className={classes.profileName}>
                                            <BlackToolTip arrow placement='top' title={viewData.name}>
                                                {viewData.name.slice(0, 14)}{viewData.name.length > 14 ? "..." : ""}
                                            </BlackToolTip>
                                        </Text>
                                    </Box>
                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                        <Text className={classes.profileId}>{viewData.reference_id}</Text>
                                        <Text mediumBoldBlue noWrap onClick={handleActivityClick} sx={{ cursor: 'pointer' }}>Activity Log</Text>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item container md={12} xs={12} columnSpacing={2} pt={2}>
                            {
                                dataArr.map((item, key) => (
                                    <>
                                        <Grid item md={3} xs={3}>
                                            <Box display='flex' flexDirection='row' gap={1}>
                                                {
                                                    current == item ?
                                                        <Text analyticsViewAll onClick={() => {
                                                            if (current == item) { }
                                                            else if (props.formEdit) {
                                                                setOpenpopup(true); setStepItem(item)
                                                            }
                                                            else {
                                                                setCurrent(item);
                                                                setActionContact('view'); setAction('view'); setStepItem(item)
                                                            }
                                                        }}>{item}</Text> :
                                                        <Text mediumBoldBlack400 onClick={() => {
                                                            if (current == item) { }
                                                            else if (props.formEdit) {
                                                                setOpenpopup(true); setStepItem(item)
                                                            }
                                                            else {
                                                                setCurrent(item);
                                                                setActionContact('view'); setAction('view'); setStepItem(item)
                                                            }
                                                        }}>{item}</Text>
                                                }
                                                {
                                                    item === 'Contact Details' && viewData.profile_percentage == 50 ?
                                                        // <Grid item container justifyContent={'flex-end'} xs={2}>
                                                        <BlackToolTip arrow placement='right' title={'Pending'}>
                                                            <img src={eVerifyPending} alt='pending' />
                                                        </BlackToolTip>
                                                        : ''
                                                }
                                            </Box>
                                        </Grid>
                                    </>
                                ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container md={12} xs={12} pt={2} justifyContent='center'>
                    <Grid item container md={11} xs={12}>
                        <Box className={current == 'Vendor Details' ? classes.responseiveBoxShadow : classes.responsiveContainer}>
                            {
                                current === "Vendor Details" ? <CompanyDetails formEdit={props.formEdit} setformEdit={props.setformEdit} id={id} action={action} setAction={setAction} state={state} setState={setState} getCompanyDetails={getCompanyDetails} loading={loading} setLoading={setLoading} getActivity={getActivity} /> : null
                            }
                            {
                                current === "Contact Details" ? <ContactDetails current={current} formEdit={props.formEdit} setformEdit={props.setformEdit} id={id} actionContact={actionContact} setActionContact={setActionContact} status={state.status} getActivity={getActivity} loading={loading} setLoading={setLoading} getCompanyDetails={getCompanyDetails} /> : null
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Hidden>
            <ReusablePopup openPopup={employeeMapped} setOpenPopup={setEmployeeMapped} white iconHide crossIcon fixedWidth>
                <Grid container justifyContent={'center'} textAlign='center' alignContent={'center'} pb={2}>
                    <img src={deactivatedPlacement} alt='noactive' />
                </Grid>
                <Grid container justifyContent={'center'} textAlign={'center'}>
                    <Text mediumOverView>This vendor has active contractors <br /> mapped to them. Please update the <br /> contractor's status before deactivating this <br/> vendor.  </Text>
                </Grid>
                <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                    <Button save14 onClick={() => navigate('/employees')}>
                        Go To Employees
                    </Button>
                </Grid>
            </ReusablePopup>
        </Box>
    );
}